import LanguageDetector from 'i18next-browser-languagedetector';

interface DetectorOptions {
  /**
   * only detect languages that are in the whitelist
   * @default true
   */
  checkWhitelist?: boolean;

  /**
   * fallback to a similar whitelist language
   * Example 1: Browser language is 'es'
   * if 'es' is not found in whitelist, first fallback to any whitelist language that starts with 'es-', then fallback to fallbackLng ('es' -> 'es-*' -> fallbackLng)
   * Example 2: Browser language is 'es-MX'
   * if 'es-MX' is not found in whitelist, first fallback to 'es', then fallback to 'es-*', then fallback to fallbackLng ('es-MX' -> 'es' -> 'es-*' -> fallbackLng)
   * @default false
   */
  checkForSimilarInWhitelist?: boolean;

  /**
   * order and from where user language should be detected
   */
  order?: Array<
    | 'querystring'
    | 'cookie'
    | 'sessionStorage'
    | 'localStorage'
    | 'navigator'
    | 'htmlTag'
    | string
  >;

  /**
   * keys or params to lookup language from
   */
  lookupQuerystring?: string;
  lookupCookie?: string;
  lookupLocalStorage?: string;
  lookupFromPathIndex?: number;
  lookupFromSubdomainIndex?: number;

  /**
   * cache user language on
   */
  caches?: string[];

  /**
   * languages to not persist (cookie, localStorage)
   */
  excludeCacheFor?: string[];

  /**
   * optional expire and domain for set cookie
   * @default 10
   */
  cookieMinutes?: number;
  cookieDomain?: string;

  /**
   * optional htmlTag with lang attribute
   * @default document.documentElement
   */
  htmlTag?: HTMLElement | null;
}

export class CustomLangageDetector extends LanguageDetector {
  protected options?: DetectorOptions;

  constructor(services?: any, options?: DetectorOptions) {
    super(services, options);
    this.options = options;
  }
}
