import { ListObject, ListValue } from 'src/api/api-gc/model';
import { APIListService } from 'src/api/api-gc/service';

const listService = new APIListService();

const ListHelper = {
  getList: (values: ListObject[], listCode: string): ListObject | undefined => {
    let result = undefined;
    for (const value of values) {
      if (listCode === value.listCode) {
        result = value;
      }
    }

    return result;
  },
  getValueById: (values: ListValue[], id: number): ListValue | undefined => {
    let result = undefined;
    for (const value of values) {
      if (id === value.valueId) {
        result = value;
      }
    }

    return result;
  },
  getValue: (values: ListValue[], code: string): ListValue | undefined => {
    let result = undefined;
    for (const value of values || []) {
      if (code === value.valueCode) {
        result = value;
      }
    }

    return result;
  },
  getValueText: (values: ListValue[], code: string) => {
    for (const value of values) {
      if (code === value.valueCode) {
        return value.valueText;
      }
    }
  },
  fetchAPIList: (
    token: string,
    orgCode: string,
    code: string,
    mapLevel?: string[]
  ) => {
    return listService.getLists(
      token,
      orgCode,
      true,
      undefined,
      undefined,
      mapLevel ? mapLevel : ['LIST_VALUE', 'TRANSLATIONS'],
      code
    );
  },
  getParameterValue: (value: ListValue, parameterCode: string) => {
    if (value && value.valueParams) {
      for (const p of value.valueParams) {
        if (p.param?.paramName === parameterCode) {
          return p.paramValueText;
        }
      }
    }

    return undefined;
  },
  checkListValueParameter: (
    value: ListValue,
    parameterCode: string,
    parameterValue: any
  ) => {
    if (value && value.valueParams) {
      for (const p of value.valueParams) {
        if (
          p.param?.paramName === parameterCode &&
          p.paramValueText === parameterValue
        ) {
          return true;
        }
      }
    }

    return false;
  },
  getListByCode: async (
    token: string,
    orgCode: string,
    listCode: string,
    mapLevel?: string[]
  ): Promise<ListObject | undefined> => {
    const {
      data: { values },
    } = await ListHelper.fetchAPIList(token, orgCode, listCode, mapLevel);
    const data = ListHelper.getList(values || [], listCode);
    if (data) {
      return data;
    } else {
      return undefined;
    }
  },
  getListValuesByCode: async (
    token: string,
    orgCode: string,
    listCode: string,
    mapLevel?: string[]
  ): Promise<ListValue[]> => {
    const result = await ListHelper.getListByCode(
      token,
      orgCode,
      listCode,
      mapLevel
    );
    return result && result.values ? result.values : [];
  },
  getDefaultValue: (values: ListValue[]) => {
    for (const value of values) {
      if (value.valueDefault) {
        return value;
      }
    }
    return undefined;
  },
  getDefaultValueCode: (values: ListValue[]) => {
    const value: ListValue | undefined = ListHelper.getDefaultValue(values);
    if (value) {
      return value.valueCode;
    } else {
      return undefined;
    }
  },
};

export default ListHelper;
