/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  OrganizationKey,
  OrganizationUrl,
  OrganizationUrlExtra,
  SearchOrganizationKeysResponse,
  SearchOrganizationUrlExtrasResponse,
  SearchOrganizationUrlsResponse,
} from 'src/api/api-gc/model';
import { Configuration } from 'src/api/api-gc/configuration';
import axios, { AxiosResponse } from 'axios';
import config from 'src/config';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIOrganizationService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a key
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createKey(
    token: string,
    orgCode: string,
    body?: OrganizationKey,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationKey>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createKey.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createKey.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<OrganizationKey>(
      `${this.basePath}/organizations/keys`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a url
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createUrl(
    token: string,
    orgCode: string,
    body?: OrganizationUrl,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createUrl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createUrl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<OrganizationUrl>(
      `${this.basePath}/organizations/urls`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a url extra
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param body
   * @param mapLevel
   */
  public createUrlExtra(
    token: string,
    orgCode: string,
    urlId: number,
    body?: OrganizationUrlExtra,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrlExtra>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createUrlExtra.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createUrlExtra.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling createUrlExtra.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<OrganizationUrlExtra>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}/extras`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the organization key
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param keyId
   * @param mapLevel
   */
  public getKey(
    token: string,
    orgCode: string,
    keyId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationKey>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getKey.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getKey.'
      );
    }

    if (keyId === null || keyId === undefined) {
      throw new Error(
        'Required parameter keyId was null or undefined when calling getKey.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<OrganizationKey>(
      `${this.basePath}/organizations/keys/${encodeURIComponent(
        String(keyId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get organization keys
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param keyCode
   * @param keyName
   * @param keyDescription
   * @param keyScope
   */
  public getKeys(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    keyCode?: string,
    keyName?: string,
    keyDescription?: string,
    keyScope?: string
  ): Promise<AxiosResponse<SearchOrganizationKeysResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getKeys.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getKeys.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (keyCode !== undefined && keyCode !== null) {
      queryParameters.append('keyCode', keyCode as any);
    }
    if (keyName !== undefined && keyName !== null) {
      queryParameters.append('keyName', keyName as any);
    }
    if (keyDescription !== undefined && keyDescription !== null) {
      queryParameters.append('keyDescription', keyDescription as any);
    }
    if (keyScope !== undefined && keyScope !== null) {
      queryParameters.append('keyScope', keyScope as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchOrganizationKeysResponse>(
      `${this.basePath}/organizations/keys`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the organization url extra
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param extraId
   * @param mapLevel
   */
  public getUrlExtras(
    token: string,
    orgCode: string,
    urlId: number,
    extraId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrlExtra>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUrlExtras.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUrlExtras.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling getUrlExtras.'
      );
    }

    if (extraId === null || extraId === undefined) {
      throw new Error(
        'Required parameter extraId was null or undefined when calling getUrlExtras.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<OrganizationUrlExtra>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}/extras/${encodeURIComponent(String(extraId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get organization url extras
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param extraKeyCode
   * @param extraFieldType
   * @param extraKeyValue
   */
  public getUrlExtras1(
    token: string,
    orgCode: string,
    urlId: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    extraKeyCode?: string,
    extraFieldType?: string,
    extraKeyValue?: string
  ): Promise<AxiosResponse<SearchOrganizationUrlExtrasResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUrlExtras1.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUrlExtras1.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling getUrlExtras1.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (extraKeyCode !== undefined && extraKeyCode !== null) {
      queryParameters.append('extraKeyCode', extraKeyCode as any);
    }
    if (extraFieldType !== undefined && extraFieldType !== null) {
      queryParameters.append('extraFieldType', extraFieldType as any);
    }
    if (extraKeyValue !== undefined && extraKeyValue !== null) {
      queryParameters.append('extraKeyValue', extraKeyValue as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchOrganizationUrlExtrasResponse>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}/extras`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the organization url
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param mapLevel
   */
  public getUrls(
    token: string,
    orgCode: string,
    urlId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUrls.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUrls.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling getUrls.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<OrganizationUrl>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get organization urls
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param urlCode
   * @param urlName
   * @param urlValue
   * @param urlDescription
   * @param urlPublic
   * @param urlUserName
   * @param urlUserPwd
   * @param urlScope
   */
  public getUrls1(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    urlCode?: string,
    urlName?: string,
    urlValue?: string,
    urlDescription?: string,
    urlPublic?: boolean,
    urlUserName?: string,
    urlUserPwd?: string,
    urlScope?: string
  ): Promise<AxiosResponse<SearchOrganizationUrlsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUrls1.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUrls1.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (urlCode !== undefined && urlCode !== null) {
      queryParameters.append('urlCode', urlCode as any);
    }
    if (urlName !== undefined && urlName !== null) {
      queryParameters.append('urlName', urlName as any);
    }
    if (urlValue !== undefined && urlValue !== null) {
      queryParameters.append('urlValue', urlValue as any);
    }
    if (urlDescription !== undefined && urlDescription !== null) {
      queryParameters.append('urlDescription', urlDescription as any);
    }
    if (urlPublic !== undefined && urlPublic !== null) {
      queryParameters.append('urlPublic', urlPublic as any);
    }
    if (urlUserName !== undefined && urlUserName !== null) {
      queryParameters.append('urlUserName', urlUserName as any);
    }
    if (urlUserPwd !== undefined && urlUserPwd !== null) {
      queryParameters.append('urlUserPwd', urlUserPwd as any);
    }
    if (urlScope !== undefined && urlScope !== null) {
      queryParameters.append('urlScope', urlScope as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchOrganizationUrlsResponse>(
      `${this.basePath}/organizations/urls`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a key
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param keyId
   * @param body
   * @param mapLevel
   */
  public modifyKey(
    token: string,
    orgCode: string,
    keyId: number,
    body?: OrganizationKey,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationKey>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyKey.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyKey.'
      );
    }

    if (keyId === null || keyId === undefined) {
      throw new Error(
        'Required parameter keyId was null or undefined when calling modifyKey.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<OrganizationKey>(
      `${this.basePath}/organizations/keys/${encodeURIComponent(
        String(keyId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a url
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param body
   * @param mapLevel
   */
  public modifyUrl(
    token: string,
    orgCode: string,
    urlId: number,
    body?: OrganizationUrl,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyUrl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyUrl.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling modifyUrl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<OrganizationUrl>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a url extra
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param extraId
   * @param body
   * @param mapLevel
   */
  public modifyUrlExtra(
    token: string,
    orgCode: string,
    urlId: number,
    extraId: number,
    body?: OrganizationUrlExtra,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrlExtra>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyUrlExtra.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyUrlExtra.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling modifyUrlExtra.'
      );
    }

    if (extraId === null || extraId === undefined) {
      throw new Error(
        'Required parameter extraId was null or undefined when calling modifyUrlExtra.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<OrganizationUrlExtra>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}/extras/${encodeURIComponent(String(extraId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an organization key
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param keyId
   */
  public removeKey(
    token: string,
    orgCode: string,
    keyId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeKey.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeKey.'
      );
    }

    if (keyId === null || keyId === undefined) {
      throw new Error(
        'Required parameter keyId was null or undefined when calling removeKey.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/organizations/keys/${encodeURIComponent(
        String(keyId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an organization url
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   */
  public removeUrl(
    token: string,
    orgCode: string,
    urlId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeUrl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeUrl.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling removeUrl.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an organization url extra
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param extraId
   */
  public removeUrlExtra(
    token: string,
    orgCode: string,
    urlId: number,
    extraId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeUrlExtra.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeUrlExtra.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling removeUrlExtra.'
      );
    }

    if (extraId === null || extraId === undefined) {
      throw new Error(
        'Required parameter extraId was null or undefined when calling removeUrlExtra.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}/extras/${encodeURIComponent(String(extraId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a key
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param keyId
   * @param body
   * @param mapLevel
   */
  public replaceKey(
    token: string,
    orgCode: string,
    keyId: number,
    body?: OrganizationKey,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationKey>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceKey.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceKey.'
      );
    }

    if (keyId === null || keyId === undefined) {
      throw new Error(
        'Required parameter keyId was null or undefined when calling replaceKey.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<OrganizationKey>(
      `${this.basePath}/organizations/keys/${encodeURIComponent(
        String(keyId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a url
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param body
   * @param mapLevel
   */
  public replaceUrl(
    token: string,
    orgCode: string,
    urlId: number,
    body?: OrganizationUrl,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceUrl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceUrl.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling replaceUrl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<OrganizationUrl>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a url extra
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param urlId
   * @param extraId
   * @param body
   * @param mapLevel
   */
  public replaceUrlExtra(
    token: string,
    orgCode: string,
    urlId: number,
    extraId: number,
    body?: OrganizationUrlExtra,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<OrganizationUrlExtra>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceUrlExtra.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceUrlExtra.'
      );
    }

    if (urlId === null || urlId === undefined) {
      throw new Error(
        'Required parameter urlId was null or undefined when calling replaceUrlExtra.'
      );
    }

    if (extraId === null || extraId === undefined) {
      throw new Error(
        'Required parameter extraId was null or undefined when calling replaceUrlExtra.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<OrganizationUrlExtra>(
      `${this.basePath}/organizations/urls/${encodeURIComponent(
        String(urlId)
      )}/extras/${encodeURIComponent(String(extraId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
