/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NavItem from './NavItem';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import * as featherIcons from 'react-feather';
import { ApiContext } from 'src/context/ApiContext';
import MenuHelper from 'src/helpers/MenuHelper';
import { TranslationHelper } from 'src/helpers';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'src/api/api-gc';
import clsx from 'clsx';
import config from 'src/config';

interface RenderNavItemsProps {
  items: any[];
  pathname: string;
  itemHref: string;
  lang: string;
  depth?: number;
}

const renderNavItems = ({
  items,
  itemHref,
  lang,
  ...rest
}: RenderNavItemsProps) => {
  return (
    <List disablePadding>
      {items
        .filter((item) => item.enable)
        .reduce(
          (acc, item) =>
            reduceChildRoutes({ acc, item, itemHref, lang, ...rest }),
          []
        )}
    </List>
  );
};

interface ReduceChildRoutesProps {
  acc: any[];
  pathname: string;
  item: MenuItem;
  itemHref: string;
  lang: string;
  depth?: number;
}

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  itemHref,
  lang,
  depth = 0,
}: ReduceChildRoutesProps) => {
  const convertToFeatherIcon = (string: any) => {
    string = string.split('-');
    string = string
      .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
      .join('');
    // @ts-ignore
    return featherIcons[string];
  };

  const key = `${item.itemId}_${depth}`;

  if (item.itemVisible) {
    if (item.itemChildren && checkChildrenVisible(item.itemChildren)) {
      const find = item.itemChildren.find(
        (v) => v.itemCommand && pathname.includes(v.itemCommand)
      );
      const isOpen = !!find;

      acc.push(
        <NavItem
          href=""
          depth={depth}
          icon={item.itemIcon && convertToFeatherIcon(item.itemIcon)}
          key={key}
          //info={item.info}
          open={isOpen}
          title={TranslationHelper.getTranslation(
            lang,
            item.itemName,
            item.titleTranslations
          )}>
          {renderNavItems({
            depth: depth + 1,
            itemHref: itemHref + '/' + item.itemCommand,
            pathname,
            items: item.itemChildren,
            lang: lang,
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={itemHref + '/' + item.itemCommand}
          icon={item.itemIcon && convertToFeatherIcon(item.itemIcon)}
          key={key}
          //info={item.info}
          title={TranslationHelper.getTranslation(
            lang,
            item.itemName,
            item.titleTranslations
          )}
        />
      );
    }
  }

  return acc;
};

const checkChildrenVisible = (children: MenuItem[]) => {
  for (const child of children) {
    if (child.itemVisible) {
      return true;
    }
  }
  return false;
};

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    top: '64px',
    width: drawerWidth,
    height: 'calc(100% - 64px)',
  },
  desktopDrawer: {
    width: drawerWidth,
    top: 64,
    height: 'calc(100% - 64px)',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
  },
  desktopDrawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  desktopDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '35px',
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'inherit',
    '&:hover': {
      width: drawerWidth,
      '& ul ul': {
        height: 'auto',
      },
      '& .scrollbar-container': {
        position: 'relative',
        overflow: 'hidden !important',
      },
    },
    '& ul ul': {
      height: 0,
    },
    '& .scrollbar-container': {
      position: 'inherit',
      overflow: 'inherit !important',
    },
  },
  userInfos: {
    width: drawerWidth,
  },
  logo: {
    background: theme.palette.primary.main,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  items: {
    '& li': {
      width: drawerWidth,
    },
  },
  footer: {
    width: drawerWidth,
  },
}));

interface NavBarProps {
  onClose: () => void;
  open: boolean;
}

const NavBar = ({ open, onClose, ...rest }: NavBarProps) => {
  // @ts-ignore
  const orgCode = useMemo(() => rest.computedMatch.params.orgCode, [rest]);

  const apiContext = useContext(ApiContext);
  const userFirstName = apiContext.user.userFirstName;
  const userLastName = apiContext.user.userLastName;

  const classes = useStyles();
  const { pathname } = useLocation();
  const isMountedRef = useIsMountedRef();
  const [menu, setMenu] = useState<MenuItem[]>([]);
  const { t, i18n } = useTranslation();

  const getMenu = useCallback(() => {
    return MenuHelper.getMenu(
      MenuHelper.getManagerMenu(apiContext.menu),
      'SIDEBAR'
    );
  }, [apiContext.menu]);

  useEffect(() => {
    const sidebarMenu = getMenu();

    if (isMountedRef.current && sidebarMenu) {
      setMenu(sidebarMenu.itemChildren || []);
    }
  }, [isMountedRef, apiContext.menu]);

  useEffect(() => {
    if (open && onClose && window.innerWidth <= 1280) {
      onClose();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const getContent = () => {
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={2} className={classes.userInfos}>
            <Box display="flex" justifyContent="center">
              <RouterLink to={`/${orgCode}/account`}>
                <Avatar alt="User" className={classes.avatar} />
              </RouterLink>
            </Box>
            <Box mt={2} textAlign="center">
              <Link
                component={RouterLink}
                to={`/${orgCode}/account`}
                variant="h5"
                color="textPrimary"
                underline="none">
                {`${userFirstName} ${userLastName}`}
              </Link>
            </Box>
          </Box>
          <Divider />
          <Box className={classes.items}>
            {renderNavItems({
              items: menu,
              pathname: pathname,
              itemHref: '/' + orgCode,
              lang: i18n.language,
            })}
          </Box>
          <Divider />
          <Box p={2} className={classes.footer}>
            <Typography variant="h6" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://sbeglobalcare.com/">
                GlobalCare
              </Link>{' '}
              {new Date().getFullYear()}
            </Typography>
            <Typography
              color="textSecondary"
              align="right"
              style={{ fontSize: '10px' }}>
              {t('main.version', { version: config.version })}
            </Typography>
          </Box>
        </PerfectScrollbar>
      </Box>
    );
  };

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onClose}
          open={open}
          variant="temporary">
          {getContent()}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.desktopDrawerOpen]: open,
              [classes.desktopDrawerClose]: !open,
            }),
          }}
          className={clsx(classes.desktopDrawer, {
            [classes.desktopDrawerOpen]: open,
            [classes.desktopDrawerClose]: !open,
          })}
          open
          onTransitionEnd={() => {
            var resizeEvent = new CustomEvent('resize');
            window.dispatchEvent(resizeEvent);
          }}
          variant="persistent">
          {getContent()}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
