/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  Entity,
  EntityParameter,
  EntityType,
  EntityTypeParameter,
  Profile,
  SearchEntitiesResponse,
  SearchEntityParametersResponse,
  SearchEntityTypeParametersResponse,
  SearchEntityTypesResponse,
  User,
} from '../model';
import { Configuration } from '../configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIEntityService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Create entityProfiles
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param profileId
   * @param mapLevel
   */
  public addProfilesToEntity(
    token: string,
    orgCode: string,
    entityId: number,
    profileId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Profile>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addProfilesToEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addProfilesToEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling addProfilesToEntity.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling addProfilesToEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<Profile>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/profiles/${encodeURIComponent(String(profileId))}`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates an entity
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */

  public createEntity(
    token: string,
    orgCode: string,
    body?: Entity,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Entity>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<Entity>(`${this.basePath}/entity/entities`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates an entityParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param body
   * @param mapLevel
   */
  public createEntityParameter(
    token: string,
    orgCode: string,
    entityId: number,
    body?: EntityParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createEntityParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createEntityParameter.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling createEntityParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<EntityParameter>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/entityParameters`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates an entityType
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createEntityType(
    token: string,
    orgCode: string,
    body?: EntityType,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createEntityType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createEntityType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<EntityType>(`${this.basePath}/entity/entityTypes`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates an entityTypeParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createEntityTypeParameter(
    token: string,
    orgCode: string,
    body?: EntityTypeParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createEntityTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createEntityTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<EntityTypeParameter>(
      `${this.basePath}/entity/entityTypeParameters`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get entities
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param entityTypeCode
   * @param entityCode
   * @param entityName
   * @param entityParentId
   * @param locale
   * @param entityChannel
   * @param entityNetwork
   * @param entityCustom1
   * @param entityCustom2
   * @param entityCustom3
   * @param entityCustom4
   * @param entityCustom5
   * @param entityStateAddress
   * @param entityAddressCityMain
   * @param entityAddressZipcode
   * @param entityTypeId
   */
  public getEntities(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    entityTypeCode?: Array<string>,
    entityCode?: Array<string>,
    entityName?: string,
    entityParentId?: number,
    locale?: string,
    entityChannel?: string,
    entityNetwork?: string,
    entityCustom1?: string,
    entityCustom2?: string,
    entityCustom3?: string,
    entityCustom4?: string,
    entityCustom5?: string,
    entityStateAddress?: string,
    entityAddressCityMain?: string,
    entityAddressZipcode?: string,
    entityTypeId?: Array<number>
  ): Promise<AxiosResponse<SearchEntitiesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntities.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntities.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.set('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.set('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.set('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (entityTypeCode) {
      entityTypeCode.forEach((element) => {
        queryParameters.append('entityTypeCode', element as any);
      });
    }
    if (entityCode) {
      entityCode.forEach((element) => {
        queryParameters.append('entityCode', element as any);
      });
    }
    if (entityName !== undefined && entityName !== null) {
      queryParameters.set('entityName', entityName as any);
    }
    if (entityParentId !== undefined && entityParentId !== null) {
      queryParameters.set('entityParentId', entityParentId as any);
    }
    if (locale !== undefined && locale !== null) {
      queryParameters.set('locale', locale as any);
    }
    if (entityChannel !== undefined && entityChannel !== null) {
      queryParameters.set('entityChannel', entityChannel as any);
    }
    if (entityNetwork !== undefined && entityNetwork !== null) {
      queryParameters.set('entityNetwork', entityNetwork as any);
    }
    if (entityCustom1 !== undefined && entityCustom1 !== null) {
      queryParameters.set('entityCustom1', entityCustom1 as any);
    }
    if (entityCustom2 !== undefined && entityCustom2 !== null) {
      queryParameters.set('entityCustom2', entityCustom2 as any);
    }
    if (entityCustom3 !== undefined && entityCustom3 !== null) {
      queryParameters.set('entityCustom3', entityCustom3 as any);
    }
    if (entityCustom4 !== undefined && entityCustom4 !== null) {
      queryParameters.set('entityCustom4', entityCustom4 as any);
    }
    if (entityCustom5 !== undefined && entityCustom5 !== null) {
      queryParameters.set('entityCustom5', entityCustom5 as any);
    }
    if (entityStateAddress !== undefined && entityStateAddress !== null) {
      queryParameters.set('entityStateAddress', entityStateAddress as any);
    }
    if (entityAddressCityMain !== undefined && entityAddressCityMain !== null) {
      queryParameters.set(
        'entityAddressCityMain',
        entityAddressCityMain as any
      );
    }
    if (entityAddressZipcode !== undefined && entityAddressZipcode !== null) {
      queryParameters.set('entityAddressZipcode', entityAddressZipcode as any);
    }
    if (entityTypeId) {
      entityTypeId.forEach((element) => {
        queryParameters.append('entityTypeId', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchEntitiesResponse>(
      `${this.basePath}/entity/entities`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the entity
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param mapLevel
   */
  public getEntity(
    token: string,
    orgCode: string,
    entityId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Entity>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<Entity>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the entityParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param entityParamId
   * @param mapLevel
   */
  public getEntityParameter(
    token: string,
    orgCode: string,
    entityId: number,
    entityParamId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntityParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntityParameter.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getEntityParameter.'
      );
    }

    if (entityParamId === null || entityParamId === undefined) {
      throw new Error(
        'Required parameter entityParamId was null or undefined when calling getEntityParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<EntityParameter>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/entityParameters/${encodeURIComponent(String(entityParamId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get entityParameters
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param paramId
   * @param paramName
   * @param paramScope
   */
  public getEntityParameters(
    token: string,
    orgCode: string,
    entityId: number,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    paramId?: Array<number>,
    paramName?: string,
    paramScope?: string
  ): Promise<AxiosResponse<SearchEntityParametersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntityParameters.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntityParameters.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getEntityParameters.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.set('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.set('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.set('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (paramId) {
      paramId.forEach((element) => {
        queryParameters.append('paramId', element as any);
      });
    }
    if (paramName !== undefined && paramName !== null) {
      queryParameters.set('paramName', paramName as any);
    }
    if (paramScope !== undefined && paramScope !== null) {
      queryParameters.set('paramScope', paramScope as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchEntityParametersResponse>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/entityParameters`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the entityType
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityTypeId
   * @param mapLevel
   */
  public getEntityType(
    token: string,
    orgCode: string,
    entityTypeId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntityType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntityType.'
      );
    }

    if (entityTypeId === null || entityTypeId === undefined) {
      throw new Error(
        'Required parameter entityTypeId was null or undefined when calling getEntityType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<EntityType>(
      `${this.basePath}/entity/entityTypes/${encodeURIComponent(
        String(entityTypeId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the entityTypeParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param paramId
   * @param mapLevel
   */
  public getEntityTypeParameter(
    token: string,
    orgCode: string,
    paramId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntityTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntityTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling getEntityTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<EntityTypeParameter>(
      `${this.basePath}/entity/entityTypeParameters/${encodeURIComponent(
        String(paramId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get entityTypeParameters
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param entityTypeId
   * @param paramName
   * @param paramType
   * @param paramMandatory
   */
  public getEntityTypeParameters(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    entityTypeId?: number,
    paramName?: string,
    paramType?: string,
    paramMandatory?: boolean
  ): Promise<AxiosResponse<SearchEntityTypeParametersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntityTypeParameters.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntityTypeParameters.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.set('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.set('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.set('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (entityTypeId !== undefined && entityTypeId !== null) {
      queryParameters.set('entityTypeId', entityTypeId as any);
    }
    if (paramName !== undefined && paramName !== null) {
      queryParameters.set('paramName', paramName as any);
    }
    if (paramType !== undefined && paramType !== null) {
      queryParameters.set('paramType', paramType as any);
    }
    if (paramMandatory !== undefined && paramMandatory !== null) {
      queryParameters.set('paramMandatory', paramMandatory as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchEntityTypeParametersResponse>(
      `${this.basePath}/entity/entityTypeParameters`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get entityTypes
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param typeCode
   * @param typeName
   * @param typeDescription
   */
  public getEntityTypes(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    typeCode?: string,
    typeName?: string,
    typeDescription?: string
  ): Promise<AxiosResponse<SearchEntityTypesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntityTypes.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntityTypes.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.set('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.set('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.set('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (typeCode !== undefined && typeCode !== null) {
      queryParameters.set('typeCode', typeCode as any);
    }
    if (typeName !== undefined && typeName !== null) {
      queryParameters.set('typeName', typeName as any);
    }
    if (typeDescription !== undefined && typeDescription !== null) {
      queryParameters.set('typeDescription', typeDescription as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchEntityTypesResponse>(
      `${this.basePath}/entity/entityTypes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get entityProfiles
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param mapLevel
   */
  public getProfilesOfEntity(
    token: string,
    orgCode: string,
    entityId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Array<Profile>>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProfilesOfEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProfilesOfEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getProfilesOfEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<Array<Profile>>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/profiles`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get user profiles of entity
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param mapLevel
   */
  public getUserProfilesOfEntity(
    token: string,
    orgCode: string,
    entityId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Array<User>>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter sessionId was null or undefined when calling getUserProfilesOfEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUserProfilesOfEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getUserProfilesOfEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<Array<User>>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/users`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an entity
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param body
   * @param mapLevel
   */
  public modifyEntity(
    token: string,
    orgCode: string,
    entityId: number,
    body?: Entity,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Entity>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling modifyEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<Entity>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an entityParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param entityParamId
   * @param body
   * @param mapLevel
   */
  public modifyEntityParameter(
    token: string,
    orgCode: string,
    entityId: number,
    entityParamId: number,
    body?: EntityParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyEntityParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyEntityParameter.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling modifyEntityParameter.'
      );
    }

    if (entityParamId === null || entityParamId === undefined) {
      throw new Error(
        'Required parameter entityParamId was null or undefined when calling modifyEntityParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<EntityParameter>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/entityParameters/${encodeURIComponent(String(entityParamId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an entityType
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityTypeId
   * @param body
   * @param mapLevel
   */
  public modifyEntityType(
    token: string,
    orgCode: string,
    entityTypeId: number,
    body?: EntityType,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyEntityType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyEntityType.'
      );
    }

    if (entityTypeId === null || entityTypeId === undefined) {
      throw new Error(
        'Required parameter entityTypeId was null or undefined when calling modifyEntityType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<EntityType>(
      `${this.basePath}/entity/entityTypes/${encodeURIComponent(
        String(entityTypeId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an entityTypeParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public modifyEntityTypeParameter(
    token: string,
    orgCode: string,
    paramId: number,
    body?: EntityTypeParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyEntityTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyEntityTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyEntityTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<EntityTypeParameter>(
      `${this.basePath}/entity/entityTypeParameters/${encodeURIComponent(
        String(paramId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an entity
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   */
  public removeEntity(
    token: string,
    orgCode: string,
    entityId: number
  ): Promise<AxiosResponse<string>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling removeEntity.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<string>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an entityParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param entityParameterId
   */
  public removeEntityParameter(
    token: string,
    orgCode: string,
    entityId: number,
    entityParameterId: number
  ): Promise<AxiosResponse<string>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeEntityParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeEntityParameter.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling removeEntityParameter.'
      );
    }

    if (entityParameterId === null || entityParameterId === undefined) {
      throw new Error(
        'Required parameter entityParameterId was null or undefined when calling removeEntityParameter.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<string>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/entityParameters/${encodeURIComponent(String(entityParameterId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an entityType
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityTypeId
   */
  public removeEntityType(
    token: string,
    orgCode: string,
    entityTypeId: number
  ): Promise<AxiosResponse<string>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeEntityType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeEntityType.'
      );
    }

    if (entityTypeId === null || entityTypeId === undefined) {
      throw new Error(
        'Required parameter entityTypeId was null or undefined when calling removeEntityType.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<string>(
      `${this.basePath}/entity/entityTypes/${encodeURIComponent(
        String(entityTypeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an entityTypeParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param paramId
   */
  public removeEntityTypeParameter(
    token: string,
    orgCode: string,
    paramId: number
  ): Promise<AxiosResponse<string>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeEntityTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeEntityTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeEntityTypeParameter.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<string>(
      `${this.basePath}/entity/entityTypeParameters/${encodeURIComponent(
        String(paramId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove entityProfile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param profileId
   */
  public removeProfilesFromEntity(
    token: string,
    orgCode: string,
    entityId: number,
    profileId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeProfilesFromEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeProfilesFromEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling removeProfilesFromEntity.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling removeProfilesFromEntity.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/profiles/${encodeURIComponent(String(profileId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an entity
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param body
   * @param mapLevel
   */
  public replaceEntity(
    token: string,
    orgCode: string,
    entityId: number,
    body?: Entity,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Entity>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceEntity.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling replaceEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<Entity>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an entityParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityId
   * @param entityParamId
   * @param body
   * @param mapLevel
   */
  public replaceEntityParameter(
    token: string,
    orgCode: string,
    entityId: number,
    entityParamId: number,
    body?: EntityParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceEntityParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceEntityParameter.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling replaceEntityParameter.'
      );
    }

    if (entityParamId === null || entityParamId === undefined) {
      throw new Error(
        'Required parameter entityParamId was null or undefined when calling replaceEntityParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<EntityParameter>(
      `${this.basePath}/entity/entities/${encodeURIComponent(
        String(entityId)
      )}/entityParameters/${encodeURIComponent(String(entityParamId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an entityType
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param entityTypeId
   * @param body
   * @param mapLevel
   */
  public replaceEntityType(
    token: string,
    orgCode: string,
    entityTypeId: number,
    body?: EntityType,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceEntityType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceEntityType.'
      );
    }

    if (entityTypeId === null || entityTypeId === undefined) {
      throw new Error(
        'Required parameter entityTypeId was null or undefined when calling replaceEntityType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<EntityType>(
      `${this.basePath}/entity/entityTypes/${encodeURIComponent(
        String(entityTypeId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an entityTypeParameter
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public replaceEntityTypeParameter(
    token: string,
    orgCode: string,
    paramId: number,
    body?: EntityTypeParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<EntityTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceEntityTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceEntityTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceEntityTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<EntityTypeParameter>(
      `${this.basePath}/entity/entityTypeParameters/${encodeURIComponent(
        String(paramId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
