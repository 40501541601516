import { v4 as uuidv4 } from 'uuid';

const ArrayHelper = {
  addingUuid: (array: Array<any>, childMapping?: string) => {
    const newArray: Array<any> = new Array<any>();
    if (array) {
      array.forEach(function (obj) {
        const item = { uuid: obj.uuid || uuidv4(), ...obj };
        if (childMapping && item[childMapping]) {
          item[childMapping] = ArrayHelper.addingUuid(
            obj[childMapping],
            childMapping
          );
        }
        newArray.push(item);
      });
    }

    return newArray;
  },
};

export default ArrayHelper;
