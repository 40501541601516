import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'src/context/SettingsContext';
import { EmitType, L10n, setCulture } from '@syncfusion/ej2-base';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnMenu,
  ColumnModel,
  ColumnsDirective,
  ContextMenu,
  Edit,
  Filter,
  Inject,
  Page,
  Reorder,
  Sort,
  Toolbar,
  TreeGridComponent as TreeGrid,
  TreeGridModel,
} from '@syncfusion/ej2-react-treegrid';
import { RecordDoubleClickEventArgs } from '@syncfusion/ej2-react-grids';
import { IconButton, Switch } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

L10n.load({
  fr_FR: require('src/utils/i18n/fr_FR.json'),
}); // load corresponding culture text

export interface IconInterface {
  icon: any;
  name: string;
  field: string;
  position: 'left' | 'right';
  onClickAction?: string;
  classname?: string;
  size?: string;
}

interface TreeGridCustomInterface extends TreeGridModel {
  data: any;
  schema: TreeGridModel;
  recordDoubleClick: EmitType<RecordDoubleClickEventArgs>;
  onChange: (e: any) => void;
  onDataEdit?(data: any, grid?: TreeGrid | null): void;
}

const getValue: any = (obj: any, path: any) => {
  if (!path || !obj) return obj;
  const properties = path.split('.');
  return getValue(obj[properties.shift()], properties.join('.'));
};

export const TreeGridComponent: React.FC<TreeGridCustomInterface> = ({
  data,
  schema,
  recordDoubleClick,
  onChange,
  onDataEdit,
}) => {
  const treeGridInstance = useRef<TreeGrid>(null);
  const { settings } = useSettings();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language || 'en-UK';

  if (treeGridInstance.current) {
    setCulture(currentLanguage);
  }

  const saveEdition = (data: any) => {
    if (onDataEdit) {
      onDataEdit(data);
    }
  };

  //const columns = schema.columns || [];
  const multipleTemplates = (data: any) => {
    if (data.column && data.column.type === 'switch') {
      const change: EmitType<React.ChangeEvent<HTMLInputElement>> = (event) => {
        if (treeGridInstance && treeGridInstance.current) {
          if (event?.target.name) {
            // Changed the dataSource if clicked on Switch and the Grid is not in edit state
            data[event.target.name] = event?.target.checked;
            treeGridInstance.current.setRowData(data.uuid, data);
            saveEdition(data);
          }
        }
      };

      const isChecked = (): boolean => {
        let checked = false;
        // @ts-ignore
        if (data[data.column.field] === true) {
          checked = true;
        }

        return checked;
      };

      return (
        <Switch
          color="primary"
          checked={isChecked()}
          name={data.column.field}
          onChange={change}
          disabled={!onDataEdit}
        />
      );
    } else {
      if (data.icons) {
        return templateWithIcon(data);
      } else {
        return <span>{getValue(data, data.column.field)}</span>;
      }
    }
  };

  const displayIcon = (icon: IconInterface, data: any) => {
    if (icon.onClickAction) {
      return (
        <IconButton
          key={`icon-${icon.field}-${icon.position}-${icon.name}`}
          //aria-label={icon}
          className={icon.classname}
          size="small"
          onClick={() => onChange({ type: 'clickAddProduct', data })}>
          {icon.icon}
        </IconButton>
      );
    } else {
      return (
        <span key={`icon-${icon.field}-${icon.position}-${icon.name}`}>
          {icon.icon}
        </span>
      );
    }
  };

  const templateWithIcon = (data?: any) => {
    return (
      <div style={{ display: 'inline' }}>
        {(data.icons || [])
          .filter(
            (icon: IconInterface) =>
              data.column.field === icon.field && icon.position === 'left'
          )
          .map((icon: IconInterface) => displayIcon(icon, data))}

        <span>{getValue(data, data.column.field)}</span>
        {(data.icons || [])
          .filter(
            (icon: IconInterface) =>
              data.column.field === icon.field && icon.position === 'right'
          )
          .map((icon: IconInterface) => displayIcon(icon, data))}
      </div>
    );
  };

  const onActionBegin = (args: any) => {
    if (args && args.requestType === 'add') {
      args.cancel = true;
      onChange(args);
    }
  };

  const addingUuid = (array: any[]) => {
    if (array) {
      array.forEach(function (obj) {
        if (obj[schema.childMapping as string]) {
          addingUuid(obj[schema.childMapping as string]);
        }
        if (!obj.uuid) {
          obj.uuid = uuidv4();
        }
      });
    }
  };

  const getData = (data: any[]) => {
    addingUuid(data);

    return data;
  };

  return (
    <div className={settings.theme.toLowerCase()}>
      <TreeGrid
        actionBegin={onActionBegin}
        allowFiltering={schema.allowFiltering}
        allowPaging={schema.allowPaging}
        allowSorting={schema.allowSorting}
        childMapping={schema.childMapping}
        contextMenuItems={schema.contextMenuItems}
        //dataSource={data}
        dataSource={getData(data)}
        editSettings={schema.editSettings}
        enableCollapseAll={schema.enableCollapseAll}
        filterSettings={schema.filterSettings}
        locale={currentLanguage}
        pageSettings={schema.pageSettings}
        recordDoubleClick={recordDoubleClick}
        ref={treeGridInstance}
        searchSettings={schema.searchSettings}
        showColumnChooser={schema.showColumnChooser}
        toolbar={schema.toolbar}
        treeColumnIndex={schema.treeColumnIndex}
        enableHover>
        <ColumnsDirective>
          {((schema.columns as ColumnModel[]) || []).map((c: any) => (
            <ColumnDirective
              key={c.field}
              allowEditing={c.allowEditing}
              allowFiltering={c.allowFiltering}
              allowReordering={c.allowReordering}
              allowResizing={c.allowResizing}
              allowSorting={c.allowSorting}
              defaultValue={c.defaultValue}
              editType={c.editType}
              field={c.field}
              headerText={t(c.headerText || '') || c.headerText}
              minWidth="8"
              showInColumnChooser={c.showInColumnChooser}
              textAlign={c.textAlign}
              template={(data: any) => {
                return multipleTemplates({ ...data, column: { ...c } });
              }}
              type={c.type}
              visible={c.visible}
              validationRules={c.type !== 'switch' ? c.validationRules : null}
              width={c.width !== null ? c.width : undefined}
            />
          ))}
          <ColumnDirective
            key="uuid"
            field="uuid"
            isPrimaryKey={true}
            isIdentity={true}
            allowEditing={false}
            visible={false}
            showInColumnChooser={false}
          />
        </ColumnsDirective>
        <Inject
          services={React.useMemo(
            () => [
              ColumnChooser,
              ColumnMenu,
              ContextMenu,
              Edit,
              Filter,
              Page,
              Reorder,
              Sort,
              Toolbar,
            ],
            []
          )}
        />
      </TreeGrid>
    </div>
  );
};
