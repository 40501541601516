import * as React from 'react';
import {
  ValidationStateSchema,
  ErrorInterface,
  FormFieldType,
} from 'src/interfaces';
import { Grid } from '@mui/material';
import { InputGuesser } from './InputGuesser';

export interface FormProps {
  className?: string;
  validationStateSchema: ValidationStateSchema;
  formData?: Record<string, any>;
  fields: FormFieldType[];
}

export class FormWidget extends React.Component<FormProps> {
  formData: Record<string, any>;

  constructor(props: FormProps) {
    super(props);

    this.state = {
      errors: {},
    };

    if (this.props.formData) {
      this.formData = this.props.formData;
    } else {
      this.formData = {};
    }
  }

  validateState() {
    let hasErrorInState = false;
    const errors: ErrorInterface = {};
    for (const key in this.props.validationStateSchema) {
      const isInputFieldRequired: boolean = this.props.validationStateSchema[
        key
      ].required;

      if (isInputFieldRequired && !this.formData[key]) {
        hasErrorInState = true;
        errors[key] = this.props.validationStateSchema[key].error || '';
      }
    }

    //this.errors = errors;
    this.setState({
      errors: errors,
    });

    return !hasErrorInState;
  }

  getFormData() {
    return this.formData;
  }

  render() {
    return (
      <Grid container className={this.props.className}>
        {(this.props.fields || []).map((item, key) => (
          <Grid item key={key} xs={12}>
            <InputGuesser
              label={item.label}
              name={item.name}
              onChange={(event) =>
                (this.formData[item.name] = event.target.value)
              }
              type={item.type}
              value={item.value || ''}
              values={item.values || []}
              // @ts-ignore
              error={!!this.state.errors[item.name]}
              // @ts-ignore
              helperText={this.state.errors[item.name]}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}
