import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { MenuItem } from 'src/api/api-gc/model';
import { ApiContext } from 'src/context';
import { TranslationHelper } from 'src/helpers';
import MenuHelper from 'src/helpers/MenuHelper';

interface BreadcrumbProps {
  orgCode: string;
}

const getBreadcrumb = (
  menu: MenuItem | undefined,
  path: string,
  initPath: string,
  breadcrumb: string,
  lang: string
) => {
  let result = '';

  if (menu) {
    if (initPath + MenuHelper.getCommand(menu) === path) {
      return (
        breadcrumb +
        ' / ' +
        TranslationHelper.getTranslation(
          lang,
          menu.itemName,
          menu.titleTranslations
        )
      );
    } else if (menu.itemChildren) {
      for (const item of menu.itemChildren) {
        const r = getBreadcrumb(
          item,
          path,
          initPath +
            (MenuHelper.getCommand(menu) !== undefined
              ? MenuHelper.getCommand(menu) + '/'
              : ''),
          !menu.itemAclHidden
            ? breadcrumb +
                ' / ' +
                TranslationHelper.getTranslation(
                  lang,
                  menu.itemName,
                  menu.titleTranslations
                )
            : breadcrumb,
          lang
        );

        if (r?.length) {
          result = r;
          break;
        }
      }
    }
  }

  return result;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ orgCode }) => {
  const apiContext = useContext(ApiContext);
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  const [breadcrumb, setBreadcrumb] = useState<string>('');

  useEffect(() => {
    let path = pathname;
    let lastCheckedPath = undefined;
    let result = '';

    while (!result && path !== lastCheckedPath) {
      result = getBreadcrumb(
        //MenuHelper.getMenu(MenuHelper.getManagerMenu(apiContext.menu), 'SIDEBAR') as MenuItem,
        MenuHelper.getManagerMenu(apiContext.menu),
        path,
        `/${orgCode}/`,
        orgCode,
        i18n.language
      );

      if (!result) {
        lastCheckedPath = path;
        path = pathname.substr(0, pathname.lastIndexOf('/'));
      }
    }

    setBreadcrumb(result);
  }, [apiContext.menu, pathname, orgCode, i18n.language]);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to={`/${orgCode}/dashboard`}>
        <Typography color="textPrimary">{orgCode}</Typography>
      </Link>
      <Typography color="textPrimary">
        {breadcrumb.split(`${orgCode} /`)[1]}
      </Typography>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
