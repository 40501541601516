import React, { useRef, useState } from 'react';
import { Box, IconButton, Popover, SvgIcon, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Bell as BellIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

function Notifications() {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Tooltip title="Notifications">
      <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
        <SvgIcon>
          <BellIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{ paper: classes.popover }}
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}>
      <Box p={2}>
        <Typography variant="h5" color="textPrimary">
          Notifications
        </Typography>
      </Box>
      <Box p={2}>
        <Typography variant="h6" color="textPrimary">
          There are no notifications
        </Typography>
      </Box>
    </Popover>
  </>;
}

export default Notifications;
