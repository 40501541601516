import { history } from 'src/App';
import { Settings } from 'src/interfaces';
import { removeToken } from 'src/utils/token';

const LogoutHelper = {
  logout: (orgCode: string, settings: Settings) => {
    removeToken();

    sessionStorage.removeItem('loginInfos');
    sessionStorage.removeItem('currentPath');

    if (settings) {
      switch (settings.loginMode) {
        case 'oauth': {
          history.push(`/${orgCode}/disconnected`);
          break;
        }
        default: {
          history.push(`/${orgCode}/login`);
          break;
        }
      }
    } else {
      history.push(`/${orgCode}/login`);
    }
  },
};

export default LogoutHelper;
