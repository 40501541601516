import React from 'react';
import { Redirect } from 'react-router-dom';
import { getToken } from 'src/utils/token';

function AuthRoute({ component: Component, render, ...rest }) {
  if (!getToken()) {
    return <Redirect to={'/' + rest.computedMatch.params.orgCode + '/login'} />;
  }

  return render ? render({ ...rest }) : <Component {...rest} />;
}

export default AuthRoute;
