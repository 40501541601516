/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useMemo, useState } from 'react';
import { ListValue, MenuItem, User } from 'src/api/api-gc/model';
import {
  BookmarkInterface,
  DisplayedTableInterface,
  UserPreferencesInterface,
} from 'src/api/api-preferences/model';
import MenuHelper from 'src/helpers/MenuHelper';

export interface OrganizationInformationsInterface {
  countries: ListValue[];
  languages: ListValue[];
  locales: ListValue[];
  timezones: ListValue[];
}

export interface LoginInformationsInterface {
  orgCode: string;
  user: User;
  orgInfos: OrganizationInformationsInterface;
  masterList: DisplayedTableInterface[];
  userPreferences: UserPreferencesInterface;
  menu: MenuItem[];
}

const defaultInfos: OrganizationInformationsInterface = {
  countries: [],
  languages: [],
  locales: [],
  timezones: [],
};

interface ApiProviderInterface {
  orgCode: string;
  masterList: DisplayedTableInterface[];
  orgInfos: OrganizationInformationsInterface;
  user: User;
  setLoginInfos: (loginInformations: LoginInformationsInterface) => void;
  userPreferencesBookmarks: BookmarkInterface[];
  userPreferencesTables: DisplayedTableInterface[];
  saveUserPreferences: (userPreferences: UserPreferencesInterface) => void;
  menu: MenuItem[];
  refreshBackOfficeMenu: (
    menu: MenuItem,
    action: 'ADD' | 'EDIT' | 'DELETE'
  ) => void;
}

export const ApiContext = createContext<ApiProviderInterface>({
  orgCode: '',
  masterList: [],
  orgInfos: defaultInfos,
  user: {},
  setLoginInfos: () => {},
  userPreferencesBookmarks: [],
  userPreferencesTables: [],
  saveUserPreferences: () => {},
  menu: [],
  refreshBackOfficeMenu: () => {},
});

export const ApiProvider: React.FC = ({ children }) => {
  const [orgCode, setOrgCode] = useState<string>('');
  const [masterList, setMasterList] = useState<DisplayedTableInterface[]>([]);
  const [orgInfos, setOrgInfos] = useState(defaultInfos);
  const [user, setUser] = useState<User>({});
  const [userPreferencesBookmarks, setUserPreferencesBookmarks] = useState<
    BookmarkInterface[]
  >([]);
  const [userPreferencesTables, setUserPreferencesTables] = useState<
    DisplayedTableInterface[]
  >([]);
  const [menu, setMenu] = useState<MenuItem[]>([]);

  const setLoginInfos = (loginInformations: LoginInformationsInterface) => {
    setOrgCode(loginInformations.orgCode);
    setUser(loginInformations.user);
    setOrgInfos(loginInformations.orgInfos);
    setMasterList(loginInformations.masterList);
    setUserPreferencesBookmarks(
      loginInformations.userPreferences.bookmarks || []
    );
    setUserPreferencesTables(loginInformations.userPreferences.tables || []);
    setMenu(loginInformations.menu);
  };

  const saveUserPreferences = (value: UserPreferencesInterface) => {
    const loginInfos = JSON.parse(
      sessionStorage.getItem('loginInfos')!
    ) as LoginInformationsInterface;

    loginInfos.userPreferences = value;

    if (
      JSON.stringify(userPreferencesBookmarks) !==
      JSON.stringify(value.bookmarks)
    ) {
      setUserPreferencesBookmarks(value.bookmarks || []);
    }

    if (
      JSON.stringify(userPreferencesTables) !== JSON.stringify(value.tables)
    ) {
      setUserPreferencesTables(value.tables || []);
    }

    sessionStorage.setItem('loginInfos', JSON.stringify(loginInfos));
  };

  const userPreferencesMemo = useMemo(
    () => ({
      userPreferencesBookmarks,
      userPreferencesTables,
      saveUserPreferences,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userPreferencesBookmarks, userPreferencesTables]
  );

  const refreshBackOfficeMenu = (
    editedMenuItem: MenuItem,
    action: 'ADD' | 'EDIT' | 'DELETE'
  ) => {
    const loginInformations: LoginInformationsInterface = JSON.parse(
      sessionStorage.getItem('loginInfos')!
    ) as LoginInformationsInterface;
    switch (action) {
      case 'ADD':
        loginInformations.menu = MenuHelper.addMenuItem(editedMenuItem, menu);
        break;
      case 'EDIT':
        loginInformations.menu = MenuHelper.replaceMenuItem(
          editedMenuItem,
          menu
        );
        break;
      case 'DELETE':
        loginInformations.menu = MenuHelper.deleteMenuItem(
          editedMenuItem,
          menu
        );
        break;
    }

    sessionStorage.setItem('loginInfos', JSON.stringify(loginInformations));

    setMenu(loginInformations.menu);
  };

  return (
    <ApiContext.Provider
      value={{
        ...{
          orgCode,
          masterList,
          orgInfos,
          user,
          setLoginInfos,
          menu,
          refreshBackOfficeMenu,
        },
        ...userPreferencesMemo,
      }}>
      {children}
    </ApiContext.Provider>
  );
};
