/* tslint:disable:no-unused-variable member-ordering */

import config from 'src/config.js';

import {
  GetOAuthLoginUrl,
  GetOAuthLoginUrlResponse,
  GetOrganizationInfoResponse,
  LoginCheck,
  LoginCheckOAuthResponseType,
  LoginCheckOAuthType,
  LoginCheckResponse,
} from '../model';
import { Configuration } from '../configuration';

import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APISessionService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * disconnect
   *
   * @param orgCode Organization Code
   * @param token
   */
  public disconnect(
    orgCode: string,
    token?: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling disconnect.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling disconnect.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.delete(`${this.basePath}/session/disconnect`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * get organization info by code
   *
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getOrganizationInfo(
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<GetOrganizationInfoResponse>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getOrganizationInfo.'
      );
    }

    const params = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        params.append('mapLevel', element);
      });
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(`${this.basePath}/session/organizationInfo`, {
      params: params,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<GetOrganizationInfoResponse>>;
  }

  /**
   * login
   *
   * @param orgCode Organization Code
   * @param body
   */
  public login(
    orgCode: string,
    body?: LoginCheck
  ): Promise<AxiosResponse<LoginCheckResponse>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling login.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post(`${this.basePath}/session/login`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      //validateStatus: validateStatus,
      validateStatus: (status) => {
        return status < 500; // Resolve only if the status code is less than 500
      },
    }) as Promise<AxiosResponse<LoginCheckResponse>>;
  }

  /**
   *
   *
   * @param orgCode Organization Code
   * @param body
   */
  public getOAuthLoginUrl(
    orgCode: string,
    body?: GetOAuthLoginUrl
  ): Promise<AxiosResponse<GetOAuthLoginUrlResponse>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling login.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<GetOAuthLoginUrlResponse>(
      `${this.basePath}/session/getOAuthLoginUrl`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        //validateStatus: validateStatus,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
  }

  /**
   *
   *
   * @param orgCode Organization Code
   * @param body
   */
  public loginCheckOAuth(
    orgCode: string,
    body?: LoginCheckOAuthType
  ): Promise<AxiosResponse<LoginCheckOAuthResponseType>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling login.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<LoginCheckOAuthResponseType>(
      `${this.basePath}/session/loginCheckOAuth`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        //validateStatus: validateStatus,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
  }
}
