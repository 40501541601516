import React from 'react';
import ImageIcon from '@mui/icons-material/Image';
import { CardMedia, Typography } from '@mui/material';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CarouselComponent.scss';

type carouselItem = {
  field: string;
  label: string;
  name: string;
  url: string;
};

interface CarouselComponentProps {
  items: carouselItem[];
}

const settings: Settings = {
  /*dots: true,
  lazyLoad: 'progressive',
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 2,*/
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const CarouselComponent = ({ items }: CarouselComponentProps) => {
  return (
    <Slider {...settings}>
      {/*{items
        .filter((i) => i.url)
        .map((i) => (
          <div key={i.label}>
            <img src={i.url} alt={i.label} />
          </div>
        ))}*/}
      {items.map((item: any, i: number) => {
        return (
          <CardMedia
            className="Media"
            image={item.url}
            title={item.name}
            key={i}>
            {!item.url && (
              <span className="noneUrl">
                <ImageIcon />
              </span>
            )}
            <Typography
              className="MediaCaption"
              style={{ marginTop: !!item.url ? '350px' : '-50px' }}>
              {item.name}
            </Typography>
          </CardMedia>
        );
      })}
    </Slider>
  );
};
