/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  Acl,
  Entity,
  Permission,
  Profile,
  ProfileParam,
  SearchAclsResponse,
  SearchPermissionsResponse,
  SearchProfileParamsResponse,
  SearchProfilesResponse,
  SearchUserApisResponse,
  SearchUsersResponse,
  User,
  UserApi,
} from 'src/api/api-gc/model';
import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIUserService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Add entityProfiles to user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   * @param entityId
   * @param profileId
   * @param mapLevel
   */
  public addEntityProfilesToUser(
    token: string,
    orgCode: string,
    userId: number,
    entityId: number,
    profileId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Profile>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addEntityProfilesToUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addEntityProfilesToUser.'
      );
    }

    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling addEntityProfilesToUser.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling addEntityProfilesToUser.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling addEntityProfilesToUser.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<Profile>(
      `${this.basePath}/users/${encodeURIComponent(
        String(userId)
      )}/entities/${encodeURIComponent(
        String(entityId)
      )}/profiles/${encodeURIComponent(String(profileId))}`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Add permissions to profile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param profileId
   * @param body
   * @param mapLevel
   */
  public addPermissionsToProfile(
    token: string,
    orgCode: string,
    profileId: number,
    body?: Array<Permission>,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Profile>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addPermissionsToProfile.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addPermissionsToProfile.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling addPermissionsToProfile.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<Profile>(
      `${this.basePath}/profiles/${encodeURIComponent(
        String(profileId)
      )}/permissions`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates an acl
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createAcl(
    token: string,
    orgCode: string,
    body?: Acl,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Acl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<Acl>(`${this.basePath}/acls`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates an userApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createUserApi(
    token: string,
    orgCode: string,
    body?: UserApi,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<UserApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createUserApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createUserApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<UserApi>(`${this.basePath}/userApis`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates a profile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createProfile(
    token: string,
    orgCode: string,
    body?: Profile,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Profile>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createProfile.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createProfile.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<Profile>(`${this.basePath}/profiles`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates an user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createUser(
    token: string,
    orgCode: string,
    body?: User,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<User>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createUser.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<User>(`${this.basePath}/users`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates a permission (conf_user_acl)
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createUserAcl(
    token: string,
    orgCode: string,
    body?: Permission,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Permission>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createUserAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createUserAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<Permission>(`${this.basePath}/permissions`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Get the acl
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param aclId
   * @param mapLevel
   */
  public getAcl(
    token: string,
    orgCode: string,
    aclId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Acl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getAcl.'
      );
    }

    if (aclId === null || aclId === undefined) {
      throw new Error(
        'Required parameter aclId was null or undefined when calling getAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<Acl>(
      `${this.basePath}/acls/${encodeURIComponent(String(aclId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get acls
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param aclLabel
   */
  public getAcls(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    aclLabel?: string
  ): Promise<AxiosResponse<SearchAclsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getAcls.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getAcls.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (aclLabel !== undefined && aclLabel !== null) {
      queryParameters.append('aclLabel', aclLabel as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchAclsResponse>(`${this.basePath}/acls`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Get the userApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param apiId
   * @param mapLevel
   */
  public getUserApi(
    token: string,
    orgCode: string,
    apiId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<UserApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUserApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUserApi.'
      );
    }

    if (apiId === null || apiId === undefined) {
      throw new Error(
        'Required parameter apiId was null or undefined when calling getUserApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<UserApi>(
      `${this.basePath}/userApis/${encodeURIComponent(String(apiId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get userApis
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param apiKeyPublic
   * @param apiKeyPassword
   * @param apiUserId
   * @param apiUserProfileId
   * @param apiEntityId
   * @param apiHttpOrigin
   * @param apiHttpHost
   * @param apiHttpReferer
   * @param apiSessionMode
   * @param apiLasttoken
   * @param apiSecureMode
   */
  public getUserApis(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    apiKeyPublic?: string,
    apiKeyPassword?: string,
    apiUserId?: number,
    apiUserProfileId?: number,
    apiEntityId?: number,
    apiHttpOrigin?: string,
    apiHttpHost?: string,
    apiHttpReferer?: string,
    apiSessionMode?: string,
    apiLasttoken?: string,
    apiSecureMode?: string
  ): Promise<AxiosResponse<SearchUserApisResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUserApis.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUserApis.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (apiKeyPublic !== undefined && apiKeyPublic !== null) {
      queryParameters.append('apiKeyPublic', apiKeyPublic as any);
    }
    if (apiKeyPassword !== undefined && apiKeyPassword !== null) {
      queryParameters.append('apiKeyPassword', apiKeyPassword as any);
    }
    if (apiUserId !== undefined && apiUserId !== null) {
      queryParameters.append('apiUserId', apiUserId as any);
    }
    if (apiUserProfileId !== undefined && apiUserProfileId !== null) {
      queryParameters.append('apiUserProfileId', apiUserProfileId as any);
    }
    if (apiEntityId !== undefined && apiEntityId !== null) {
      queryParameters.append('apiEntityId', apiEntityId as any);
    }
    if (apiHttpOrigin !== undefined && apiHttpOrigin !== null) {
      queryParameters.append('apiHttpOrigin', apiHttpOrigin as any);
    }
    if (apiHttpHost !== undefined && apiHttpHost !== null) {
      queryParameters.append('apiHttpHost', apiHttpHost as any);
    }
    if (apiHttpReferer !== undefined && apiHttpReferer !== null) {
      queryParameters.append('apiHttpReferer', apiHttpReferer as any);
    }
    if (apiSessionMode !== undefined && apiSessionMode !== null) {
      queryParameters.append('apiSessionMode', apiSessionMode as any);
    }
    if (apiLasttoken !== undefined && apiLasttoken !== null) {
      queryParameters.append('apiLasttoken', apiLasttoken as any);
    }
    if (apiSecureMode !== undefined && apiSecureMode !== null) {
      queryParameters.append('apiSecureMode', apiSecureMode as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchUserApisResponse>(`${this.basePath}/userApis`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Get entity profiles of user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   * @param mapLevel
   */
  public getEntityProfilesOfUser(
    token: string,
    orgCode: string,
    userId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Array<Entity>>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntityProfilesOfUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntityProfilesOfUser.'
      );
    }

    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling getEntityProfilesOfUser.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<Array<Entity>>(
      `${this.basePath}/users/${encodeURIComponent(String(userId))}/entities`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the profile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param profileId
   * @param mapLevel
   */
  public getProfile(
    token: string,
    orgCode: string,
    profileId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Profile>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProfile.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProfile.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling getProfile.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<Profile>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get profiles
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param profileName
   * @param profileDefaultEntityId
   */
  public getProfiles(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    profileName?: string,
    profileDefaultEntityId?: number
  ): Promise<AxiosResponse<SearchProfilesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProfiles.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProfiles.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (profileName !== undefined && profileName !== null) {
      queryParameters.append('profileName', profileName as any);
    }
    if (
      profileDefaultEntityId !== undefined &&
      profileDefaultEntityId !== null
    ) {
      queryParameters.append(
        'profileDefaultEntityId',
        profileDefaultEntityId as any
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchProfilesResponse>(`${this.basePath}/profiles`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Get the user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   * @param mapLevel
   */
  public getUser(
    token: string,
    orgCode: string,
    userId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<User>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUser.'
      );
    }

    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling getUser.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<User>(
      `${this.basePath}/users/${encodeURIComponent(String(userId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the permission (conf_user_acl)
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param permissionId
   * @param mapLevel
   */
  public getUserAcl(
    token: string,
    orgCode: string,
    permissionId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Permission>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUserAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUserAcl.'
      );
    }

    if (permissionId === null || permissionId === undefined) {
      throw new Error(
        'Required parameter permissionId was null or undefined when calling getUserAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<Permission>(
      `${this.basePath}/permissions/${encodeURIComponent(
        String(permissionId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get permission (conf_user_acl)
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param aclId
   * @param aclLabel
   * @param aclRead
   * @param aclCreate
   * @param aclUpdate
   * @param aclDelete
   * @param aclExec
   */
  public getUserAcls(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    aclId?: number,
    aclLabel?: string,
    aclRead?: number,
    aclCreate?: number,
    aclUpdate?: number,
    aclDelete?: number,
    aclExec?: number
  ): Promise<AxiosResponse<SearchPermissionsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUserAcls.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUserAcls.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (aclId !== undefined && aclId !== null) {
      queryParameters.append('aclId', aclId as any);
    }
    if (aclLabel !== undefined && aclLabel !== null) {
      queryParameters.append('aclLabel', aclLabel as any);
    }
    if (aclRead !== undefined && aclRead !== null) {
      queryParameters.append('aclRead', aclRead as any);
    }
    if (aclCreate !== undefined && aclCreate !== null) {
      queryParameters.append('aclCreate', aclCreate as any);
    }
    if (aclUpdate !== undefined && aclUpdate !== null) {
      queryParameters.append('aclUpdate', aclUpdate as any);
    }
    if (aclDelete !== undefined && aclDelete !== null) {
      queryParameters.append('aclDelete', aclDelete as any);
    }
    if (aclExec !== undefined && aclExec !== null) {
      queryParameters.append('aclExec', aclExec as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchPermissionsResponse>(
      `${this.basePath}/permissions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get users
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param userName
   * @param userFirstName
   * @param userLastName
   * @param userEmail
   * @param userLang
   * @param userCountry
   * @param userLocale
   * @param userTimezone
   * @param userReferenceExt
   * @param userNoRestriction
   * @param userNeedNewPwd
   * @param userNoConnection
   * @param userHttp
   */
  public getUsers(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    userName?: string,
    userFirstName?: string,
    userLastName?: string,
    userEmail?: string,
    userLang?: string,
    userCountry?: string,
    userLocale?: string,
    userTimezone?: string,
    userReferenceExt?: string,
    userNoRestriction?: boolean,
    userNeedNewPwd?: boolean,
    userNoConnection?: boolean,
    userHttp?: boolean
  ): Promise<AxiosResponse<SearchUsersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUsers.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUsers.'
      );
    }
    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (userName !== undefined && userName !== null) {
      queryParameters.append('userName', userName as any);
    }
    if (userFirstName !== undefined && userFirstName !== null) {
      queryParameters.append('userFirstName', userFirstName as any);
    }
    if (userLastName !== undefined && userLastName !== null) {
      queryParameters.append('userLastName', userLastName as any);
    }
    if (userEmail !== undefined && userEmail !== null) {
      queryParameters.append('userEmail', userEmail as any);
    }
    if (userLang !== undefined && userLang !== null) {
      queryParameters.append('userLang', userLang as any);
    }
    if (userCountry !== undefined && userCountry !== null) {
      queryParameters.append('userCountry', userCountry as any);
    }
    if (userLocale !== undefined && userLocale !== null) {
      queryParameters.append('userLocale', userLocale as any);
    }
    if (userTimezone !== undefined && userTimezone !== null) {
      queryParameters.append('userTimezone', userTimezone as any);
    }
    if (userReferenceExt !== undefined && userReferenceExt !== null) {
      queryParameters.append('userReferenceExt', userReferenceExt as any);
    }
    if (userNoRestriction !== undefined && userNoRestriction !== null) {
      queryParameters.append('userNoRestriction', userNoRestriction as any);
    }
    if (userNeedNewPwd !== undefined && userNeedNewPwd !== null) {
      queryParameters.append('userNeedNewPwd', userNeedNewPwd as any);
    }
    if (userNoConnection !== undefined && userNoConnection !== null) {
      queryParameters.append('userNoConnection', userNoConnection as any);
    }
    if (userHttp !== undefined && userHttp !== null) {
      queryParameters.append('userHttp', userHttp as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchUsersResponse>(`${this.basePath}/users`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Modify an acl
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param aclId
   * @param body
   * @param mapLevel
   */
  public modifyAcl(
    token: string,
    orgCode: string,
    aclId: number,
    body?: Acl,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Acl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyAcl.'
      );
    }

    if (aclId === null || aclId === undefined) {
      throw new Error(
        'Required parameter aclId was null or undefined when calling modifyAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<Acl>(
      `${this.basePath}/acls/${encodeURIComponent(String(aclId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an userApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param apiId
   * @param body
   * @param mapLevel
   */
  public modifyUserApi(
    token: string,
    orgCode: string,
    apiId: number,
    body?: UserApi,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<UserApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyUserApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyUserApi.'
      );
    }

    if (apiId === null || apiId === undefined) {
      throw new Error(
        'Required parameter apiId was null or undefined when calling modifyUserApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<UserApi>(
      `${this.basePath}/userApis/${encodeURIComponent(String(apiId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a profile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param profileId
   * @param body
   * @param mapLevel
   */
  public modifyProfile(
    token: string,
    orgCode: string,
    profileId: number,
    body?: Profile,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Profile>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyProfile.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyProfile.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling modifyProfile.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<Profile>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   * @param body
   * @param mapLevel
   */
  public modifyUser(
    token: string,
    orgCode: string,
    userId: number,
    body?: User,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<User>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyUser.'
      );
    }

    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling modifyUser.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<User>(
      `${this.basePath}/users/${encodeURIComponent(String(userId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a permission (conf_user_acl)
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param permissionId
   * @param body
   * @param mapLevel
   */
  public modifyUserAcl(
    token: string,
    orgCode: string,
    permissionId: number,
    body?: Permission,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Permission>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyUserAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyUserAcl.'
      );
    }

    if (permissionId === null || permissionId === undefined) {
      throw new Error(
        'Required parameter permissionId was null or undefined when calling modifyUserAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<Permission>(
      `${this.basePath}/permissions/${encodeURIComponent(
        String(permissionId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an acl
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param aclId
   */
  public removeAcl(
    token: string,
    orgCode: string,
    aclId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeAcl.'
      );
    }

    if (aclId === null || aclId === undefined) {
      throw new Error(
        'Required parameter aclId was null or undefined when calling removeAcl.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/acls/${encodeURIComponent(String(aclId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an userApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param apiId
   */
  public removeUserApi(
    token: string,
    orgCode: string,
    apiId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeUserApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeUserApi.'
      );
    }

    if (apiId === null || apiId === undefined) {
      throw new Error(
        'Required parameter apiId was null or undefined when calling removeUserApi.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/userApis/${encodeURIComponent(String(apiId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove entityProfile from user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   * @param entityId
   * @param profileId
   */
  public removeEntityProfilesFromUser(
    token: string,
    orgCode: string,
    userId: number,
    entityId: number,
    profileId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeEntityProfilesFromUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeEntityProfilesFromUser.'
      );
    }

    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling removeEntityProfilesFromUser.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling removeEntityProfilesFromUser.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling removeEntityProfilesFromUser.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/users/${encodeURIComponent(
        String(userId)
      )}/entities/${encodeURIComponent(
        String(entityId)
      )}/profiles/${encodeURIComponent(String(profileId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a permission (conf_user_acl)
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param permissionId
   */
  public removePermission(
    token: string,
    orgCode: string,
    permissionId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removePermission.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removePermission.'
      );
    }

    if (permissionId === null || permissionId === undefined) {
      throw new Error(
        'Required parameter permissionId was null or undefined when calling removePermission.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/permissions/${encodeURIComponent(
        String(permissionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove permissions from profile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param profileId
   * @param permissionId
   */
  public removePermissionsFromProfile(
    token: string,
    orgCode: string,
    profileId: number,
    permissionId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removePermissionsFromProfile.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removePermissionsFromProfile.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling removePermissionsFromProfile.'
      );
    }

    if (permissionId === null || permissionId === undefined) {
      throw new Error(
        'Required parameter permissionId was null or undefined when calling removePermissionsFromProfile.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/profiles/${encodeURIComponent(
        String(profileId)
      )}/permissions/${encodeURIComponent(String(permissionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a profile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param profileId
   */
  public removeProfile(
    token: string,
    orgCode: string,
    profileId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeProfile.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeProfile.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling removeProfile.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   */
  public removeUser(
    token: string,
    orgCode: string,
    userId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeUser.'
      );
    }

    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling removeUser.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/users/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an acl
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param aclId
   * @param body
   * @param mapLevel
   */
  public replaceAcl(
    token: string,
    orgCode: string,
    aclId: number,
    body?: Acl,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Acl>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceAcl.'
      );
    }

    if (aclId === null || aclId === undefined) {
      throw new Error(
        'Required parameter aclId was null or undefined when calling replaceAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<Acl>(
      `${this.basePath}/acls/${encodeURIComponent(String(aclId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an userApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param apiId
   * @param body
   * @param mapLevel
   */
  public replaceUserApi(
    token: string,
    orgCode: string,
    apiId: number,
    body?: UserApi,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<UserApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceUserApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceUserApi.'
      );
    }

    if (apiId === null || apiId === undefined) {
      throw new Error(
        'Required parameter apiId was null or undefined when calling replaceUserApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<UserApi>(
      `${this.basePath}/userApis/${encodeURIComponent(String(apiId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a profile
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param profileId
   * @param body
   * @param mapLevel
   */
  public replaceProfile(
    token: string,
    orgCode: string,
    profileId: number,
    body?: Profile,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Profile>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceProfile.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceProfile.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling replaceProfile.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<Profile>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an user
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   * @param body
   * @param mapLevel
   */
  public replaceUser(
    token: string,
    orgCode: string,
    userId: number,
    body?: User,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<User>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceUser.'
      );
    }

    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling replaceUser.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<User>(
      `${this.basePath}/users/${encodeURIComponent(String(userId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a permission (conf_user_acl)
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param permissionId
   * @param body
   * @param mapLevel
   */
  public replaceUserAcl(
    token: string,
    orgCode: string,
    permissionId: number,
    body?: Permission,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Permission>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceUserAcl.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceUserAcl.'
      );
    }

    if (permissionId === null || permissionId === undefined) {
      throw new Error(
        'Required parameter permissionId was null or undefined when calling replaceUserAcl.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<Permission>(
      `${this.basePath}/permissions/${encodeURIComponent(
        String(permissionId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a profileParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createProfileParam(
    token: string,
    orgCode: string,
    body?: ProfileParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ProfileParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createProfileParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createProfileParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<ProfileParam>(`${this.basePath}/profiles/params`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Get the profileParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param mapLevel
   */
  public getProfileParam(
    token: string,
    orgCode: string,
    paramId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ProfileParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProfileParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProfileParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling getProfileParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<ProfileParam>(
      `${this.basePath}/profiles/params/${encodeURIComponent(String(paramId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get profileParams
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   */
  public getProfileParams(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean
  ): Promise<AxiosResponse<SearchProfileParamsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProfileParams.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProfileParams.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchProfileParamsResponse>(
      `${this.basePath}/profiles/params`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a profileParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public modifyProfileParam(
    token: string,
    orgCode: string,
    paramId: number,
    body?: ProfileParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ProfileParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyProfileParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyProfileParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyProfileParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<ProfileParam>(
      `${this.basePath}/profiles/params/${encodeURIComponent(String(paramId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a profileParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   */
  public removeProfileParam(
    token: string,
    orgCode: string,
    paramId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeProfileParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeProfileParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeProfileParam.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/profiles/params/${encodeURIComponent(String(paramId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a profileParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public replaceProfileParam(
    token: string,
    orgCode: string,
    paramId: number,
    body?: ProfileParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ProfileParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceProfileParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceProfileParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceProfileParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<ProfileParam>(
      `${this.basePath}/profiles/params/${encodeURIComponent(String(paramId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
