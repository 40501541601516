import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Settings } from 'src/interfaces';

type SettingsContextProps = {
  settings: Settings;
  saveSettings: (updatedSettings?: {}) => void;
  removeSettings: (removedSettings?: string[]) => void;
};

const defaultSettings: Settings = {
  direction: 'ltr',
  locale: 'fr_FR',
  responsiveFontSizes: true,
  theme: 'ONE_DARK',
};

const SettingsContext = React.createContext<SettingsContextProps>({
  settings: defaultSettings,
  saveSettings: () => {
    return;
  },
  removeSettings: () => {
    return;
  },
});

export const SettingsProvider: React.FC<{ settings: Settings }> = ({
  settings,
  children,
}) => {
  const [currentSettings, setCurrentSettings] = React.useState<Settings>(
    settings || defaultSettings
  );
  const { i18n } = useTranslation(undefined, { useSuspense: false });

  if (!settings) {
    localStorage.setItem('settings', JSON.stringify(defaultSettings));
    document.body.className = defaultSettings.theme.toLowerCase();
  }

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = Object.assign(currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    localStorage.setItem('settings', JSON.stringify(mergedSettings));
    i18n.changeLanguage(mergedSettings.locale);

    document.body.className = mergedSettings.theme.toLowerCase();
  };

  const handleRemoveSettings = (settings: string[] = []) => {
    for (const property of settings) {
      delete currentSettings[property as keyof Settings];
    }

    setCurrentSettings(currentSettings);
    localStorage.setItem('settings', JSON.stringify(currentSettings));
    //i18n.changeLanguage(mergedSettings.locale);
  };

  React.useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
        removeSettings: handleRemoveSettings,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return React.useContext(SettingsContext);
};

export function restoreSettings() {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
      document.body.className = settings.theme.toLowerCase();
    }
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return settings;
}

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
