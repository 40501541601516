import React, { useContext } from 'react';
import { MenuItem } from '@mui/material';
import { ApiContext } from 'src/context';
import TranslationHelper from 'src/helpers/TranslationHelper';
import { SelectBox } from 'src/components/Common';
import { useTranslation } from 'react-i18next';

interface LanguageSelectorProps {
  onchange: (value: string) => void;
  value: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  onchange,
  value,
}) => {
  const {
    orgInfos: { locales },
  } = useContext(ApiContext);
  const { i18n } = useTranslation();

  return (
    <SelectBox
      label="Language"
      name="select-language"
      value={value}
      onChange={(e) => {
        onchange(e.target.value as string);
      }}>
      {(locales || []).map((value) => (
        <MenuItem key={value.valueCode} value={value.valueCode}>
          {TranslationHelper.getTranslation(
            i18n.language,
            value.valueText,
            value.translations
          )}
        </MenuItem>
      ))}
    </SelectBox>
  );
};

export default LanguageSelector;
