import config from 'src/config.js';

import { MenuItem, SearchMenuItemsResponse } from '../model';
import { Configuration } from '../configuration';

import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIMenuService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a menuItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createMenuItem(
    token: string,
    orgCode: string,
    body?: MenuItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<MenuItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createMenuItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createMenuItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<MenuItem>(`${this.basePath}/menuItems`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  public getMenuItem(
    token: string,
    orgCode: string,
    itemId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<MenuItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getMenuItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getMenuItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling getMenuItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(
      `${this.basePath}/menuItems/${encodeURIComponent(String(itemId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<MenuItem>>;
  }

  public getMenuItems(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    itemParentId?: number,
    itemGroup?: string,
    itemName?: string,
    itemType?: string,
    onlyGrantedItems?: boolean
  ): Promise<AxiosResponse<SearchMenuItemsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getMenuItems.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getMenuItems.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (itemParentId !== undefined && itemParentId !== null) {
      queryParameters.append('itemParentId', itemParentId as any);
    }
    if (itemGroup !== undefined && itemGroup !== null) {
      queryParameters.append('itemGroup', itemGroup as any);
    }
    if (itemName !== undefined && itemName !== null) {
      queryParameters.append('itemName', itemName as any);
    }
    if (itemType !== undefined && itemType !== null) {
      queryParameters.append('itemType', itemType as any);
    }
    if (onlyGrantedItems !== undefined && onlyGrantedItems !== null) {
      queryParameters.append('onlyGrantedItems', onlyGrantedItems as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(`${this.basePath}/menuItems`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
      params: queryParameters,
    }) as Promise<AxiosResponse<SearchMenuItemsResponse>>;
  }

  /**
   * Modify a menuItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param itemId
   * @param body
   * @param mapLevel
   */
  public modifyMenuItem(
    token: string,
    orgCode: string,
    itemId: number,
    body?: MenuItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<MenuItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyMenuItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyMenuItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling modifyMenuItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<MenuItem>(
      `${this.basePath}/menuItems/${encodeURIComponent(String(itemId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a menuItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param itemId
   */
  public removeMenuItem(
    token: string,
    orgCode: string,
    itemId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeMenuItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeMenuItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling removeMenuItem.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/menuItems/${encodeURIComponent(String(itemId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a menuItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param itemId
   * @param body
   * @param mapLevel
   */
  public replaceMenuItem(
    token: string,
    orgCode: string,
    itemId: number,
    body?: MenuItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<MenuItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceMenuItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceMenuItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling replaceMenuItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<MenuItem>(
      `${this.basePath}/menuItems/${encodeURIComponent(String(itemId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
