import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import config from 'src/config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: '40px',
    height: '40px',
    marginRight: '8px',
  },
  text: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '22px',
    fontWeight: 100,
    letterSpacing: '1px',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
}));

const Logo = (props: any) => {
  const classes = useStyles();
  const getOrgCode = () => {
    return props.computedMatch.params.orgCode;
  };

  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        alt="SBE GlobalCare"
        src="/static/gc-logo.svg"
      />
      <span className={classes.text}>
        <b>{getOrgCode()}</b> / {config.env} environment
      </span>
    </div>
  );
};

export default Logo;
