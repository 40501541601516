import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextFieldComponent } from 'src/components/UI';
import { ValidationStateSchema } from 'src/interfaces';
import ValidationStateSchemaHelper from 'src/helpers/ValidationStateSchemaHelper';

export interface OptionType {
  key: string | number;
  label: string;
}

interface ComboBoxInterface {
  label?: string;
  options: OptionType[];
  value?: string | number;
  onChange: (e: any, v: any) => void;
  className?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  required?: boolean;
  name?: string;
  validationSchema?: ValidationStateSchema;
}

/*const useStyles = makeStyles(() =>
  createStyles({
    clearIndicator: {
      '& button': {
        color: 'inherit',
      },
    },
    popupIndicator: {
      '& button': {
        color: 'inherit',
      },
    },
  })
);*/

export const ComboBoxComponent: React.FC<ComboBoxInterface> = ({
  label,
  options,
  value,
  onChange,
  className,
  error,
  helperText,
  disabled,
  size,
  required,
  name,
  validationSchema,
}) => {
  //const classes = useStyles();
  const [v, setV] = useState<number | string | null | undefined>(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option: any, value: any) =>
        option.key === value.key
      }
      value={options.find((option) => option.key === v) || null}
      renderInput={(params) => (
        <TextFieldComponent
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          // @ts-ignore
          title={params.inputProps ? params.inputProps.value || null : null}
          //required={required}
          required={
            validationSchema && name
              ? ValidationStateSchemaHelper.isRequired(validationSchema, name)
              : required
          }
        />
      )}
      onChange={(e: React.ChangeEvent<{}>, va: OptionType | null) => {
        setV(va !== null ? va.key : null);
        onChange(e, va);
      }}
      className={className}
      size={size}
    />
  );
};
