import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import { InputValueInterface } from 'src/interfaces';
import { TextFieldComponent } from 'src/components/UI';

interface AsyncAutocompleteProps {
  onChange: (...event: any[]) => void;
  label: string;
  getRessources: () => Promise<InputValueInterface[]>;
  value: string;
  placeholder?: string;
  freeSolo?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

export default function AsyncAutocomplete({
  label,
  onChange,
  getRessources,
  value,
  freeSolo,
  placeholder,
  required,
  error,
  helperText,
}: AsyncAutocompleteProps) {
  const [options, setOptions] = React.useState<InputValueInterface[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const result = await getRessources();

      setOptions(result);
      setLoading(false);
    })();
  }, [getRessources]);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      options={options ?? []}
      getOptionLabel={(option) => {
        const result = (options || []).find(
          (item) => option.value === item.value
        );
        return result ? (result.label as string) : (option.value as string);
      }}
      value={value ? { value: value } : null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_event, value) => {
        onChange(
          value === null
            ? undefined
            : typeof value === 'string'
            ? value
            : (value as InputValueInterface).value
        );
      }}
      renderInput={(params) => (
        <TextFieldComponent
          {...params}
          label={label}
          onChange={(e) => {
            if (freeSolo) {
              onChange(e.target.value);
            }
          }}
          required={required}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
