import * as React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';
import { AppProviders, restoreSettings, SettingsProvider } from './context';
import './i18n';

const settings = restoreSettings();

ReactDOM.render(
  <SettingsProvider settings={settings}>
    <AppProviders>
      <App />
    </AppProviders>
  </SettingsProvider>,
  document.getElementById('root')
);
