import { MenuItem } from 'src/api/api-gc/model';
import { TranslationHelper } from './index';

const MenuHelper = {
  getManagerMenu: function (menus: MenuItem[]): MenuItem | undefined {
    if (menus && menus.length > 0) {
      for (const menu of menus) {
        if (
          menu &&
          menu.itemName === 'MENU' &&
          menu.itemGroup === 'BO_ADMIN' &&
          !menu.itemParentId
        ) {
          return menu;
        }
      }
    }
  },

  getMenu: (
    menu: MenuItem | undefined,
    menuCode: string
  ): MenuItem | undefined => {
    if (menu) {
      if (menu.itemName === menuCode) {
        return menu;
      } else if (menu.itemChildren) {
        for (const m of menu.itemChildren) {
          const result = MenuHelper.getMenu(m, menuCode);

          if (result !== undefined) {
            return result;
          }
        }
      }
    }
  },

  getMenuTitle: (
    menu: MenuItem | undefined,
    path: string,
    initPath: string,
    lang: string
  ): string => {
    let result = '';
    if (menu) {
      if (initPath + MenuHelper.getCommand(menu) === path) {
        const translation = TranslationHelper.getTranslation(
          lang,
          menu.itemName,
          menu.titleTranslations
        );

        return translation ? translation : '';
      } else if (menu.itemChildren) {
        menu.itemChildren.forEach((m: MenuItem) => {
          const r = MenuHelper.getMenuTitle(
            m,
            path,
            initPath +
              (MenuHelper.getCommand(menu) !== undefined
                ? MenuHelper.getCommand(menu) + '/'
                : ''),
            lang
          );

          if (r?.length) {
            result = r;
          }
        });
      }
    }
    return result;
  },

  getCommand: (menu: MenuItem): string | undefined => {
    if (menu.itemCommand) {
      return menu.itemCommand.split(/[?]/)[0];
    } else {
      return undefined;
    }
  },

  getPath: (
    menu: MenuItem | undefined,
    command: string,
    initPath?: string
  ): string | undefined => {
    let result = undefined;
    if (menu) {
      if (!menu.itemChildren && MenuHelper.getCommand(menu) === command) {
        return (initPath || '') + MenuHelper.getCommand(menu);
      } else if (menu.itemChildren) {
        menu.itemChildren.forEach((m: MenuItem) => {
          const r = MenuHelper.getPath(
            m,
            command,
            (initPath || '') +
              (MenuHelper.getCommand(menu) !== undefined
                ? MenuHelper.getCommand(menu) + '/'
                : '')
          );

          if (r) {
            result = r;
          }
        });
      }
    }
    return result;
  },
  replaceMenuItem: (
    editedMenuItem: MenuItem,
    menuItems: MenuItem[]
  ): MenuItem[] => {
    return menuItems.map((menuItem) => {
      if (editedMenuItem.itemId === menuItem.itemId) {
        return editedMenuItem;
      } else if (menuItem.itemChildren) {
        return {
          ...menuItem,
          itemChildren: MenuHelper.replaceMenuItem(
            editedMenuItem,
            menuItem.itemChildren
          ),
        };
      } else {
        return menuItem;
      }
    });
  },
  addMenuItem: (newMenuItem: MenuItem, menuItems: MenuItem[]): MenuItem[] => {
    return menuItems.map((menuItem) => {
      if (newMenuItem.itemParentId === menuItem.itemId) {
        menuItem.itemChildren = (menuItem.itemChildren || [])?.concat(
          newMenuItem
        );
        menuItem.itemChildren = menuItem.itemChildren.sort((a, b) =>
          a.itemOrder! < b.itemOrder! ? -1 : 1
        );
        return menuItem;
      } else if (menuItem.itemChildren) {
        return {
          ...menuItem,
          itemChildren: MenuHelper.addMenuItem(
            newMenuItem,
            menuItem.itemChildren
          ),
        };
      } else {
        return menuItem;
      }
    });
  },
  deleteMenuItem: (
    deletedMenuItem: MenuItem,
    menuItems: MenuItem[]
  ): MenuItem[] => {
    return menuItems.map((menuItem) => {
      if (deletedMenuItem.itemParentId === menuItem.itemId) {
        menuItem.itemChildren = (menuItem.itemChildren || [])?.filter(
          (item) => item.itemId !== deletedMenuItem.itemId
        );

        return menuItem;
      } else if (menuItem.itemChildren) {
        return {
          ...menuItem,
          itemChildren: MenuHelper.deleteMenuItem(
            deletedMenuItem,
            menuItem.itemChildren
          ),
        };
      } else {
        return menuItem;
      }
    });
  },
};

export default MenuHelper;
