import React from 'react';
import {
  FormControlLabel,
  MenuItem,
  Switch,
} from '@mui/material';
import SelectBox from 'src/components/Common/SelectBox';
import { Autocomplete } from '@mui/material';
import { InputValueInterface } from 'src/interfaces';
import { TextFieldComponent } from 'src/components/UI';

export const AUTOCOMPLETE_INPUT = 'AUTOCOMPLETE_INPUT';
export const SELECT_INPUT = 'SELECT_INPUT';
export const SWITCH_INPUT = 'SWITCH_INPUT';

export interface InputGuesserInterface {
  //allowEmpty?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  label: string;
  multiline?: boolean;
  name: string;
  onChange?: (e: any, v?: any) => void;
  rows?: number;
  type?: string;
  value?: InputValueInterface | string | boolean | number;
  values?: InputValueInterface[];
  //withFormControl?: boolean;
  error?: boolean;
  helperText?: string;
}

export const InputGuesser: React.FC<InputGuesserInterface> = ({
  disabled,
  type,
  value,
  ...rest
}) => {
  switch (type) {
    case AUTOCOMPLETE_INPUT: {
      const { label, onChange, values } = rest;

      return (
        <Autocomplete
          options={values ?? []}
          /*value={{
            ...rest.values?.find(
              item => (value as InputValueInterface)?.value === item.value
            ),
          }}*/
          isOptionEqualToValue={(o: InputValueInterface, v: InputValueInterface) =>
            o.value === (v.value ?? '')
          }
          onChange={onChange}
          getOptionLabel={(option) =>
            (option as InputValueInterface).label ?? ''
          }
          renderInput={(params) => (
            <InputGuesser
              {...{ ...params, label }}
              name=""
              onChange={() => null}
            />
          )}
        />
      );
    }
    case SELECT_INPUT: {
      return (
        <SelectBox {...rest} value={value?.toString() ?? ''}>
          {(rest.values || []).map(({ label, value }, key) => (
            <MenuItem value={value || ''} key={key}>
              {label}
            </MenuItem>
          ))}
        </SelectBox>
      );
    }
    case SWITCH_INPUT: {
      return rest.label ? (
        <FormControlLabel
          {...rest}
          control={<Switch {...rest} checked={!!value} />}
        />
      ) : (
        <Switch
          {...{
            [disabled ? 'checked' : 'defaultChecked']: !!value,
            ...rest,
          }}
        />
      );
    }
    default: {
      return (
        <TextFieldComponent {...rest} fullWidth value={value ?? ''} />
      );
    }
  }
};
