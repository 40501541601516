import * as React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import Routes from 'src/Routes';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      a: {
        textDecoration: 'none',
      },
    },
  })
);

export const history = createBrowserHistory({ basename: '/' });

const App: React.FC = () => {
  useStyles();

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

export default App;
