import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { ButtonComponent } from '../UI/Button/ButtonComponent';
import { FormWidget } from '../Form/FormWidget';
import { FormFieldType, ValidationStateSchema } from '../../interfaces';
import { useTranslation } from 'react-i18next';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant?: 'danger' | 'info' | 'form';
  title?: string;
  description?: string;
  fields?: FormFieldType[];
  validationStateSchema?: ValidationStateSchema;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: (value: any) => void;
  onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  fields,
  validationStateSchema,
}) => {
  const formRef = useRef<FormWidget>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {variant === 'form' && (
          <>
            <FormWidget
              ref={formRef}
              validationStateSchema={validationStateSchema || {}}
              fields={fields || []}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {variant === 'danger' && (
          <>
            <ButtonComponent
              label={t('modals.confirmation.buttons.cancel')}
              onClick={onClose}
              autoFocus
            />
            <ButtonComponent
              label={t('modals.confirmation.buttons.confirm')}
              color="primary"
              onClick={onSubmit}
            />
          </>
        )}

        {variant === 'info' && (
          <ButtonComponent
            label={t('modals.confirmation.buttons.ok')}
            color="primary"
            onClick={onSubmit}
          />
        )}

        {variant === 'form' && (
          <>
            <ButtonComponent
              label={t('modals.confirmation.buttons.cancel')}
              onClick={onClose}
              autoFocus
            />
            <ButtonComponent
              label={t('modals.confirmation.buttons.confirm')}
              color="primary"
              onClick={() => {
                if (formRef.current!.validateState()) {
                  onSubmit(formRef.current!.formData);
                }
              }}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
