/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { CircularProgress, Modal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface LoadingScreenContextInterface {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const defaultLoadingContext: LoadingScreenContextInterface = {
  loading: false,
  setLoading: () => null,
};

export const LoadingScreenContext = React.createContext<LoadingScreenContextInterface>(
  defaultLoadingContext
);

const useStyles = makeStyles(() => ({
  loading: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
}));

export const LoadingScreenProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(
    defaultLoadingContext.loading
  );

  const classes = useStyles();

  return (
    <LoadingScreenContext.Provider
      value={{
        loading,
        setLoading,
      }}>
      {children}
      <Modal// `disableBackdropClick` is removed by codemod.
// You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)
 open={loading} disableAutoFocus={true} disableEnforceFocus={true}>
        <CircularProgress className={classes.loading} />
      </Modal>
    </LoadingScreenContext.Provider>
  );
};
