import React from 'react';
import ChipInput from './ChipInput';
import clsx from 'clsx';

interface ChipInputCustomInterface {
  defaultValue: string;
  placeholder?: string;
  label?: string;
  onChange: (v: any) => void;
  className?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
}

export const ChipInputComponent: React.FC<ChipInputCustomInterface> = ({
  defaultValue,
  placeholder,
  label,
  onChange,
  className,
  error,
  helperText,
  fullWidth = true,
}) => {
  const handleChange = (tags: any) => {
    const productTextIndex = tags.join(' ');
    onChange(productTextIndex);
  };

  const mapChips = (chips: any) => {
    if (chips) {
      const split = chips.split(/(?:,| |;)+/);
      return split;
    } else {
      return [];
    }
  };

  return (
    <ChipInput
      //error={error}
      helperText={helperText}
      defaultValue={mapChips(defaultValue)}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      //style={{ width: '100%' }}
      onChange={handleChange}
      newChipKeyCodes={[13, 32]}
      //className={className}
      fullWidth={fullWidth}
      blurBehavior="add"
    />
  );
};
