import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import Account from './Account';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
  },
  toolbar: {
    minHeight: 64,
  },
  menuButton: {
    marginRight: '10px',
  },
}));

function TopBar({ onNavOpen, ...rest }) {
  const classes = useStyles();

  const getOrgCode = () => {
    return rest.computedMatch.params.orgCode;
  };

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={onNavOpen}
          size="large">
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
        <RouterLink to={`/${getOrgCode()}/`}>
          <Logo {...rest} />
        </RouterLink>
        <Box ml={2} flexGrow={1} />
        <Search />
        <Notifications />
        <Settings />
        <Box ml={2}>
          <Account {...rest} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onNavOpen: PropTypes.func,
};

export default TopBar;
