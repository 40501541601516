import { ValidationStateResponse, ValidationStateSchema } from 'src/interfaces';
import { InsuranceCoverage } from '../api/api-gc';

const ValidationStateSchemaHelper = {
  isRequired: function (
    schema: ValidationStateSchema,
    field: string
  ): boolean | undefined {
    if (schema && field) {
      return schema[field] ? schema[field].required : undefined;
    }
  },
  validateState: function (
    schema: ValidationStateSchema,
    data: any
  ): ValidationStateResponse {
    let hasErrorInState = false;
    const errors: Record<string, string> = {};
    for (const key in schema) {
      const isInputFieldRequired: boolean = schema[key].required;

      if (isInputFieldRequired && !data[key]) {
        hasErrorInState = true;
        errors[key] = schema[key].error || '';
      }
    }

    return { hasError: hasErrorInState, errors };
  },
};

export default ValidationStateSchemaHelper;
