import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { match, RouteProps, useLocation } from 'react-router';
import { CardMedia, CircularProgress, Grid } from '@mui/material';
import Page from 'src/components/Page';
import ReactDOM from 'react-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { APISessionService } from 'src/api/api-gc/service';
import {
  ApiContext,
  LoginInformationsInterface,
  OrganizationInformationsInterface,
  useSettings,
} from 'src/context';
import { history } from 'src/App';
import { setToken } from '../../utils/token';

const sessionService = new APISessionService();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    paddingTop: 25,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    objectFit: 'contain',
    backgroundSize: 'contain',
    height: '100%',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const OAuthSuccess: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = ({ ...rest }) => {
  const orgCode = useMemo(() => {
    return rest.computedMatch.params.orgCode;
  }, [rest]);
  const apiContext = useContext(ApiContext);
  const { settings, saveSettings } = useSettings();

  const classes = useStyles();
  const container = useRef<HTMLDivElement>(null);

  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');

  useEffect(() => {
    const redirect = () => {
      const currentPath = sessionStorage.getItem('currentPath');
      if (currentPath && currentPath.startsWith(`/${orgCode}/`)) {
        history.push(currentPath);
      } else {
        history.push(`/${orgCode}/dashboard`);
      }
    };

    if (orgCode) {
      sessionService
        .loginCheckOAuth(orgCode, {
          authorizationToken: token as string,
          mapLevel: [],
        })
        .then(
          ({
            data: {
              masterList,
              userPreferences,
              session,
              menuItems,
              token,
              listCountry,
              listLang,
              listLocale,
              timezones,
            },
          }) => {
            if (session) {
              const currentUser = session.sessionUser;

              const orgInfosWithTimezones: OrganizationInformationsInterface = {
                countries: listCountry?.values || [],
                languages: listLang?.values || [],
                locales: listLocale?.values || [],
                timezones: timezones || [],
              };

              const loginInformations: LoginInformationsInterface = {
                orgCode: orgCode,
                user: currentUser!,
                orgInfos: orgInfosWithTimezones,
                masterList: masterList!,
                userPreferences: userPreferences!,
                menu: menuItems || [],
              };

              sessionStorage.setItem(
                'loginInfos',
                JSON.stringify(loginInformations)
              );

              apiContext.setLoginInfos(loginInformations);
              saveSettings({
                locale: session.sessionLocale,
                loginMode: 'oauth',
              });

              setToken(token);

              redirect();
            }
          }
        );
    }
  }, [orgCode]);

  useEffect(() => {
    const fixHeight = () => {
      if (container && container.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(container.current) as HTMLElement;
        if (element) {
          const offsetTop = element.offsetTop;

          element.style.height = window.innerHeight - offsetTop - 125 + 'px';
        }
      }
    };

    fixHeight();

    window.addEventListener('resize', fixHeight);

    return () => {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);

  return (
    <Page title="GlobalCare Manager - AOuth" className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.container} ref={container}>
          <CardMedia
            className={classes.media}
            image="/static/images/login.svg"
            title="GlobalCare Manager"
          />
        </Grid>
        <Grid
          item
          xs
          style={{
            display: 'flex',
          }}
          className={classes.loading}>
          <CircularProgress />
        </Grid>
      </Grid>
    </Page>
  );
};

export default OAuthSuccess;
