import version from 'src/version';

const config = {
  version: version,
  env: 'LIVE',
  app: {
    defaultLang: 'en_UK',
    menu: {
      menuGroup: 'BO_ADMIN',
      itemName: 'MENU',
    },
  },
  api: {
    apiDashboard: {
      URL: 'https://www01.sbeglobalcare.com/gc-manager-server/dashboard',
    },
    apiPreferences: {
      URL: 'https://www01.sbeglobalcare.com/gc-manager-server/manager',
    },
    apiGc: {
      URL: 'https://www01.sbeglobalcare.com/gc-manager-server/rest',
    },
  },
};

export default config;
