import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import clsx from 'clsx';
import { useConfirmation } from 'src/components/ConfirmDialog';

const useStyles = makeStyles(() => ({
  formControl: (props: { size?: 'small' | 'medium' | undefined }) => {
    if (props.size === 'small')
      return {
        marginTop: '0',
        marginBottom: '0',
        '& label[data-shrink="false"]': {
          transform: 'translate(14px, 8px) scale(1)',
        },
      };
    else
      return {
        marginTop: '0',
        marginBottom: '0',
      };
  },
  select: (props: { size?: 'small' | 'medium' | undefined }) => {
    if (props.size === 'small')
      return {
        height: 39,
        '& div.MuiSelect-root': {
          paddingTop: 10.5,
          paddingBottom: 10.5,
        },
      };
    else return {};
  },
  label: {},
}));

interface Props {
  label?: string;
  value?: string | null;
  name?: string;
  size?: 'small' | 'medium' | undefined;
  className?: string;
  onChange?: (e: SelectChangeEvent<string>) => void;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  id?: string;
  disable?: boolean;
  showConfirmationPopin?: boolean;
  confirmationDialogTitle?: string;
  confirmationDialogDescription?: string;
  fullWidth?: boolean;
}

const SelectBox: React.FC<Props> = ({
  children,
  label,
  value,
  name,
  size,
  className,
  onChange,
  error,
  helperText,
  required,
  disable,
  showConfirmationPopin,
  confirmationDialogTitle,
  confirmationDialogDescription,
  fullWidth = true,
  ...rest
}) => {
  const classes = useStyles({ size: size });
  const [componentValue, setComponentValue] = useState(value || '');
  const [componentChildren, setComponentChildren] = useState(children);

  const confirm = useConfirmation();

  useEffect(() => {
    setComponentValue(value || '');
  }, [value]);

  useEffect(() => {
    setComponentChildren(children);
  }, [children]);

  const tryToChange = (e: SelectChangeEvent<string>) => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: confirmationDialogTitle,
      description: confirmationDialogDescription,
    }).then(() => {
      handleChange(e);
    });
  };

  const handleChange = (e: SelectChangeEvent<string>) => {
    setComponentValue(e.target.value as string);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      margin="normal"
      className={classes.formControl}
      variant="outlined"
      error={error}>
      <InputLabel
        className={clsx(classes.label, className)}
        required={required}>
        {label}
      </InputLabel>
      <Select
        label={required ? `${label} *` : label}
        className={clsx(classes.select, className)}
        {...{ name }}
        value={React.Children.count(componentChildren) ? componentValue : ''}
        onChange={showConfirmationPopin ? tryToChange : handleChange}
        disabled={disable}
        {...rest}>
        <MenuItem value="" style={{ height: '36px' }} />
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export const NativeSelectBox: React.FC<Props> = ({
  children,
  label,
  value,
  name,
  size,
  className,
  onChange,
  error,
  helperText,
  required,
  disable,
  showConfirmationPopin,
  confirmationDialogTitle,
  confirmationDialogDescription,
  fullWidth = true,
  ...rest
}) => {
  const classes = useStyles({ size: size });
  const [componentValue, setComponentValue] = useState(value || '');
  const [componentChildren, setComponentChildren] = useState(children);

  const confirm = useConfirmation();

  useEffect(() => {
    setComponentValue(value || '');
  }, [value]);

  useEffect(() => {
    setComponentChildren(children);
  }, [children]);

  const tryToChange = (e: SelectChangeEvent<string>) => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: confirmationDialogTitle,
      description: confirmationDialogDescription,
    }).then(() => {
      handleChange(e);
    });
  };

  const handleChange = (e: SelectChangeEvent<string>) => {
    setComponentValue(e.target.value as string);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      margin="normal"
      className={classes.formControl}
      variant="outlined"
      error={error}>
      <InputLabel
        className={clsx(classes.label, className)}
        required={required}>
        {label}
      </InputLabel>
      <Select
        native
        label={required ? `${label} *` : label}
        className={clsx(classes.select, className)}
        {...{ name }}
        value={React.Children.count(componentChildren) ? componentValue : ''}
        onChange={showConfirmationPopin ? tryToChange : handleChange}
        disabled={disable}
        {...rest}>
        <option value="" style={{ height: '36px' }} />
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectBox;
