/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.3-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  InsuranceCoverage,
  InsuranceEquipment,
  InsuranceOffer,
  InsurancePolicy,
  SearchInsuranceCoveragesResponse,
  SearchInsuranceEquipmentsResponse,
  SearchInsuranceOffersResponse,
  SearchInsurancePoliciesResponse,
} from '../model';

import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIInsuranceService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates an insuranceCoverage
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param body
   * @param mapLevel
   */
  public createInsuranceCoverage(
    token: string,
    orgCode: string,
    policyId: number,
    body?: InsuranceCoverage,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceCoverage>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createInsuranceCoverage.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createInsuranceCoverage.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling createInsuranceCoverage.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<InsuranceCoverage>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}/coverages`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates an insuranceEquipment
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createInsuranceEquipment(
    token: string,
    orgCode: string,
    body?: InsuranceEquipment,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceEquipment>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createInsuranceEquipment.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createInsuranceEquipment.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<InsuranceEquipment>(
      `${this.basePath}/insurance/equipments`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates an insuranceOffer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createInsuranceOffer(
    token: string,
    orgCode: string,
    body?: InsuranceOffer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceOffer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createInsuranceOffer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createInsuranceOffer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<InsuranceOffer>(
      `${this.basePath}/insurance/offers`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates an insurancePolicy
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createInsurancePolicy(
    token: string,
    orgCode: string,
    body?: InsurancePolicy,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsurancePolicy>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createInsurancePolicy.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createInsurancePolicy.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<InsurancePolicy>(
      `${this.basePath}/insurance/policies`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the insuranceCoverage
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param coverageId
   * @param mapLevel
   */
  public getInsuranceCoverage(
    token: string,
    orgCode: string,
    policyId: number,
    coverageId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceCoverage>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsuranceCoverage.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsuranceCoverage.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling getInsuranceCoverage.'
      );
    }

    if (coverageId === null || coverageId === undefined) {
      throw new Error(
        'Required parameter coverageId was null or undefined when calling getInsuranceCoverage.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<InsuranceCoverage>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}/coverages/${encodeURIComponent(String(coverageId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get insuranceCoverages
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param coverageCode
   * @param coverageName
   */
  public getInsuranceCoverages(
    token: string,
    orgCode: string,
    policyId: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    coverageCode?: string,
    coverageName?: string
  ): Promise<AxiosResponse<SearchInsuranceCoveragesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsuranceCoverages.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsuranceCoverages.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling getInsuranceCoverages.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (coverageCode !== undefined && coverageCode !== null) {
      queryParameters.append('coverageCode', coverageCode as any);
    }
    if (coverageName !== undefined && coverageName !== null) {
      queryParameters.append('coverageName', coverageName as any);
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchInsuranceCoveragesResponse>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}/coverages`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the insuranceEquipment
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param equipmentId
   * @param mapLevel
   */
  public getInsuranceEquipment(
    token: string,
    orgCode: string,
    equipmentId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceEquipment>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsuranceEquipment.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsuranceEquipment.'
      );
    }

    if (equipmentId === null || equipmentId === undefined) {
      throw new Error(
        'Required parameter equipmentId was null or undefined when calling getInsuranceEquipment.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<InsuranceEquipment>(
      `${this.basePath}/insurance/equipments/${encodeURIComponent(
        String(equipmentId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get insuranceEquipments
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param categoryId
   * @param manufacturerCode
   * @param productId
   * @param maxClaimNumber
   * @param priceMin
   * @param priceMax
   * @param maxAge
   * @param maxItemAllowed
   * @param registrationMandatory
   * @param maxAmountPerYear
   * @param maxAmountPerClaim
   * @param productCode
   * @param productName
   */
  public getInsuranceEquipments(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    categoryId?: number,
    manufacturerCode?: string,
    productId?: number,
    maxClaimNumber?: number,
    priceMin?: number,
    priceMax?: number,
    maxAge?: number,
    maxItemAllowed?: number,
    registrationMandatory?: boolean,
    maxAmountPerYear?: number,
    maxAmountPerClaim?: number,
    productCode?: string,
    productName?: string
  ): Promise<AxiosResponse<SearchInsuranceEquipmentsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsuranceEquipments.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsuranceEquipments.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (categoryId !== undefined && categoryId !== null) {
      queryParameters.append('categoryId', categoryId as any);
    }
    if (manufacturerCode !== undefined && manufacturerCode !== null) {
      queryParameters.append('manufacturerCode', manufacturerCode as any);
    }
    if (productId !== undefined && productId !== null) {
      queryParameters.append('productId', productId as any);
    }
    if (maxClaimNumber !== undefined && maxClaimNumber !== null) {
      queryParameters.append('maxClaimNumber', maxClaimNumber as any);
    }
    if (priceMin !== undefined && priceMin !== null) {
      queryParameters.append('priceMin', priceMin as any);
    }
    if (priceMax !== undefined && priceMax !== null) {
      queryParameters.append('priceMax', priceMax as any);
    }
    if (maxAge !== undefined && maxAge !== null) {
      queryParameters.append('maxAge', maxAge as any);
    }
    if (maxItemAllowed !== undefined && maxItemAllowed !== null) {
      queryParameters.append('maxItemAllowed', maxItemAllowed as any);
    }
    if (registrationMandatory !== undefined && registrationMandatory !== null) {
      queryParameters.append(
        'registrationMandatory',
        registrationMandatory as any
      );
    }
    if (maxAmountPerYear !== undefined && maxAmountPerYear !== null) {
      queryParameters.append('maxAmountPerYear', maxAmountPerYear as any);
    }
    if (maxAmountPerClaim !== undefined && maxAmountPerClaim !== null) {
      queryParameters.append('maxAmountPerClaim', maxAmountPerClaim as any);
    }
    if (productCode !== undefined && productCode !== null) {
      queryParameters.append('productCode', productCode as any);
    }
    if (productName !== undefined && productName !== null) {
      queryParameters.append('productName', productName as any);
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchInsuranceEquipmentsResponse>(
      `${this.basePath}/insurance/equipments`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the insuranceOffer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param offerId
   * @param mapLevel
   */
  public getInsuranceOffer(
    token: string,
    orgCode: string,
    offerId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceOffer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsuranceOffer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsuranceOffer.'
      );
    }

    if (offerId === null || offerId === undefined) {
      throw new Error(
        'Required parameter offerId was null or undefined when calling getInsuranceOffer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<InsuranceOffer>(
      `${this.basePath}/insurance/offers/${encodeURIComponent(
        String(offerId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get insuranceOffers
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param offerCode
   * @param offerName
   */
  public getInsuranceOffers(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    offerCode?: string,
    offerName?: string
  ): Promise<AxiosResponse<SearchInsuranceOffersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsuranceOffers.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsuranceOffers.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (offerCode !== undefined && offerCode !== null) {
      queryParameters.append('offerCode', offerCode as any);
    }
    if (offerName !== undefined && offerName !== null) {
      queryParameters.append('offerName', offerName as any);
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchInsuranceOffersResponse>(
      `${this.basePath}/insurance/offers`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get insurancePolicies
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param policyCode
   * @param policyName
   */
  public getInsurancePolicies(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    policyCode?: string,
    policyName?: string
  ): Promise<AxiosResponse<SearchInsurancePoliciesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsurancePolicies.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsurancePolicies.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (policyCode !== undefined && policyCode !== null) {
      queryParameters.append('policyCode', policyCode as any);
    }
    if (policyName !== undefined && policyName !== null) {
      queryParameters.append('policyName', policyName as any);
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchInsurancePoliciesResponse>(
      `${this.basePath}/insurance/policies`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the insurancePolicy
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param mapLevel
   */
  public getInsurancePolicy(
    token: string,
    orgCode: string,
    policyId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsurancePolicy>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getInsurancePolicy.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getInsurancePolicy.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling getInsurancePolicy.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<InsurancePolicy>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an insuranceCoverage
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param coverageId
   * @param body
   * @param mapLevel
   */
  public modifyInsuranceCoverage(
    token: string,
    orgCode: string,
    policyId: number,
    coverageId: number,
    body?: InsuranceCoverage,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceCoverage>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyInsuranceCoverage.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyInsuranceCoverage.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling modifyInsuranceCoverage.'
      );
    }

    if (coverageId === null || coverageId === undefined) {
      throw new Error(
        'Required parameter coverageId was null or undefined when calling modifyInsuranceCoverage.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<InsuranceCoverage>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}/coverages/${encodeURIComponent(String(coverageId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an insuranceEquipment
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param equipmentId
   * @param body
   * @param mapLevel
   */
  public modifyInsuranceEquipment(
    token: string,
    orgCode: string,
    equipmentId: number,
    body?: InsuranceEquipment,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceEquipment>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyInsuranceEquipment.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyInsuranceEquipment.'
      );
    }

    if (equipmentId === null || equipmentId === undefined) {
      throw new Error(
        'Required parameter equipmentId was null or undefined when calling modifyInsuranceEquipment.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<InsuranceEquipment>(
      `${this.basePath}/insurance/equipments/${encodeURIComponent(
        String(equipmentId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an insuranceOffer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param offerId
   * @param body
   * @param mapLevel
   */
  public modifyInsuranceOffer(
    token: string,
    orgCode: string,
    offerId: number,
    body?: InsuranceOffer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceOffer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyInsuranceOffer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyInsuranceOffer.'
      );
    }

    if (offerId === null || offerId === undefined) {
      throw new Error(
        'Required parameter offerId was null or undefined when calling modifyInsuranceOffer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<InsuranceOffer>(
      `${this.basePath}/insurance/offers/${encodeURIComponent(
        String(offerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an insurancePolicy
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param body
   * @param mapLevel
   */
  public modifyInsurancePolicy(
    token: string,
    orgCode: string,
    policyId: number,
    body?: InsurancePolicy,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsurancePolicy>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyInsurancePolicy.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyInsurancePolicy.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling modifyInsurancePolicy.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<InsurancePolicy>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an insuranceCoverage
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param coverageId
   */
  public removeInsuranceCoverage(
    token: string,
    orgCode: string,
    policyId: number,
    coverageId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeInsuranceCoverage.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeInsuranceCoverage.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling removeInsuranceCoverage.'
      );
    }

    if (coverageId === null || coverageId === undefined) {
      throw new Error(
        'Required parameter coverageId was null or undefined when calling removeInsuranceCoverage.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}/coverages/${encodeURIComponent(String(coverageId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an insuranceEquipment
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param equipmentId
   */
  public removeInsuranceEquipment(
    token: string,
    orgCode: string,
    equipmentId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeInsuranceEquipment.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeInsuranceEquipment.'
      );
    }

    if (equipmentId === null || equipmentId === undefined) {
      throw new Error(
        'Required parameter equipmentId was null or undefined when calling removeInsuranceEquipment.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/insurance/equipments/${encodeURIComponent(
        String(equipmentId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an insuranceOffer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param offerId
   */
  public removeInsuranceOffer(
    token: string,
    orgCode: string,
    offerId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeInsuranceOffer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeInsuranceOffer.'
      );
    }

    if (offerId === null || offerId === undefined) {
      throw new Error(
        'Required parameter offerId was null or undefined when calling removeInsuranceOffer.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/insurance/offers/${encodeURIComponent(
        String(offerId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an insurancePolicy
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   */
  public removeInsurancePolicy(
    token: string,
    orgCode: string,
    policyId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeInsurancePolicy.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeInsurancePolicy.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling removeInsurancePolicy.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an insuranceCoverage
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param coverageId
   * @param body
   * @param mapLevel
   */
  public replaceInsuranceCoverage(
    token: string,
    orgCode: string,
    policyId: number,
    coverageId: number,
    body?: InsuranceCoverage,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceCoverage>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceInsuranceCoverage.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceInsuranceCoverage.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling replaceInsuranceCoverage.'
      );
    }

    if (coverageId === null || coverageId === undefined) {
      throw new Error(
        'Required parameter coverageId was null or undefined when calling replaceInsuranceCoverage.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<InsuranceCoverage>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}/coverages/${encodeURIComponent(String(coverageId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an insuranceEquipment
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param equipmentId
   * @param body
   * @param mapLevel
   */
  public replaceInsuranceEquipment(
    token: string,
    orgCode: string,
    equipmentId: number,
    body?: InsuranceEquipment,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceEquipment>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceInsuranceEquipment.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceInsuranceEquipment.'
      );
    }

    if (equipmentId === null || equipmentId === undefined) {
      throw new Error(
        'Required parameter equipmentId was null or undefined when calling replaceInsuranceEquipment.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<InsuranceEquipment>(
      `${this.basePath}/insurance/equipments/${encodeURIComponent(
        String(equipmentId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an insuranceOffer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param offerId
   * @param body
   * @param mapLevel
   */
  public replaceInsuranceOffer(
    token: string,
    orgCode: string,
    offerId: number,
    body?: InsuranceOffer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsuranceOffer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceInsuranceOffer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceInsuranceOffer.'
      );
    }

    if (offerId === null || offerId === undefined) {
      throw new Error(
        'Required parameter offerId was null or undefined when calling replaceInsuranceOffer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<InsuranceOffer>(
      `${this.basePath}/insurance/offers/${encodeURIComponent(
        String(offerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an insurancePolicy
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param policyId
   * @param body
   * @param mapLevel
   */
  public replaceInsurancePolicy(
    token: string,
    orgCode: string,
    policyId: number,
    body?: InsurancePolicy,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<InsurancePolicy>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceInsurancePolicy.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceInsurancePolicy.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling replaceInsurancePolicy.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<InsurancePolicy>(
      `${this.basePath}/insurance/policies/${encodeURIComponent(
        String(policyId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
