/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.2-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import config from 'src/config.js';

import { Mapping, SearchMappingsResponse } from '../model';
import { Configuration } from '../configuration';
import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIMappingsService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a mapping
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createMapping(
    token: string,
    orgCode: string,
    body?: Mapping,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Mapping>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createMapping.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createMapping.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<Mapping>(`${this.basePath}/mappings`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Delete a mapping
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapId
   */
  public deleteMapping(
    token: string,
    orgCode: string,
    mapId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteMapping.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteMapping.'
      );
    }

    if (mapId === null || mapId === undefined) {
      throw new Error(
        'Required parameter mapId was null or undefined when calling deleteMapping.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/mappings/${encodeURIComponent(String(mapId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a mapping
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapId
   * @param mapLevel
   */
  public getMapping(
    token: string,
    orgCode: string,
    mapId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Mapping>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getMapping.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getMapping.'
      );
    }

    if (mapId === null || mapId === undefined) {
      throw new Error(
        'Required parameter mapId was null or undefined when calling getMapping.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<Mapping>(
      `${this.basePath}/mappings/${encodeURIComponent(String(mapId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search mappings
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param mapGroup
   * @param mapValueIn
   * @param mapValueOut
   * @param mapListIn
   * @param mapListOut
   */
  public getMappings(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    mapGroup?: string,
    mapValueIn?: string,
    mapValueOut?: string,
    mapListIn?: string,
    mapListOut?: string
  ): Promise<AxiosResponse<SearchMappingsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getMappings.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getMappings.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.set('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.set('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.set('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (mapGroup !== undefined && mapGroup !== null) {
      queryParameters.set('mapGroup', mapGroup as any);
    }
    if (mapValueIn !== undefined && mapValueIn !== null) {
      queryParameters.set('mapValueIn', mapValueIn as any);
    }
    if (mapValueOut !== undefined && mapValueOut !== null) {
      queryParameters.set('mapValueOut', mapValueOut as any);
    }
    if (mapListIn !== undefined && mapListIn !== null) {
      queryParameters.set('mapListIn', mapListIn as any);
    }
    if (mapListOut !== undefined && mapListOut !== null) {
      queryParameters.set('mapListOut', mapListOut as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchMappingsResponse>(`${this.basePath}/mappings`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Modify a mapping
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapId
   * @param body
   * @param mapLevel
   */
  public modifyMapping(
    token: string,
    orgCode: string,
    mapId: number,
    body?: Mapping,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Mapping>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyMapping.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyMapping.'
      );
    }

    if (mapId === null || mapId === undefined) {
      throw new Error(
        'Required parameter mapId was null or undefined when calling modifyMapping.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<Mapping>(
      `${this.basePath}/mappings/${encodeURIComponent(String(mapId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a mapping
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapId
   * @param body
   * @param mapLevel
   */
  public replaceMapping(
    token: string,
    orgCode: string,
    mapId: number,
    body?: Mapping,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Mapping>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceMapping.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceMapping.'
      );
    }

    if (mapId === null || mapId === undefined) {
      throw new Error(
        'Required parameter mapId was null or undefined when calling replaceMapping.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<Mapping>(
      `${this.basePath}/mappings/${encodeURIComponent(String(mapId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
