import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layout/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthRoute from 'src/components/AuthRoute';
import GuestRoute from 'src/components/GuestRoute';
import Login from './views/login/Login';
import OAuth from './views/login/OAuth';
import OAuthFail from './views/login/OAuthFail';
import OAuthSuccess from './views/login/OAuthSuccess';
import Logout from './views/login/Logout';
import NotFound from './views/pages/notfound';

const pathList = '/:orgCode/(.*?/|)list/:listCode';
const pathCategory = '/:orgCode/(.*?/|)category/:categoryCode';
const pathMapGroup = '/:orgCode/(.*?/|)map-group/:mapGroup';

export const routes = [
  {
    path: '/:orgCode/(.*?/|)entity-types',
    file: () => import('src/views/config/entityTypes/EntityTypesListView'),
  },
  {
    path: '/:orgCode/(.*?/|)entity-types/create',
    file: () => import('src/views/config/entityTypes/EntityTypeCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)entity-types/:id',
    file: () => import('src/views/config/entityTypes/EntityTypeEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)menus',
    file: () => import('src/views/config/menus/MenuScreen'),
  },
  {
    path: '/:orgCode/(.*?/|)lists',
    file: () => import('src/views/config/lists/ListsView'),
  },
  {
    path: '/:orgCode/(.*?/|)lists/create',
    file: () => import('src/views/config/lists/ListCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)lists/:id',
    file: () => import('src/views/config/lists/ListEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)organization-keys',
    file: () =>
      import('src/views/config/organizationKeys/OrganizationKeysView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-form',
    file: () => import('src/views/config/screeningForm/ScreeningFormView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-form/create',
    file: () =>
      import('src/views/config/screeningForm/ScreeningFormCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-form/:id',
    file: () => import('src/views/config/screeningForm/ScreeningFormEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)urls',
    file: () => import('src/views/config/urls/UrlsListView'),
  },
  {
    path: '/:orgCode/(.*?/|)urls/create',
    file: () => import('src/views/config/urls/UrlCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)urls/:id',
    file: () => import('src/views/config/urls/UrlEditView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/conditions/:action/:id',
    file: () => import('src/views/brl/ConditionDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/functions/:action/:id',
    file: () => import('src/views/brl/FunctionDetailsView'),
  },

  {
    path: '/:orgCode/claim-rules/rules/enums/:action/:id',
    file: () => import('src/views/brl/EnunDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/actions/:action/:id',
    file: () => import('src/views/brl/ActionDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/entities/:action/:id',
    file: () => import('src/views/brl/EntityDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules',
    file: () => import('src/views/brl/RulesView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/brs/:action/:id',
    file: () => import('src/views/brl/RuleDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/brlgroups/:action/:id',
    file: () => import('src/views/brl/GroupDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/tests',
    file: () => import('src/views/brl/TestsView'),
  },
  {
    path: '/:orgCode/claim-rules/tests/test/:action/:id',
    file: () => import('src/views/brl/TestDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/services',
    file: () => import('src/views/brl/ServicesView'),
  },
  {
    path: '/:orgCode/claim-rules/configuration',
    file: () => import('src/views/brl/ConfigurationView'),
  },
  {
    path: '/:orgCode/dashboard',
    //file: () => import('src/views/DashboardView'),
    file: () => import('src/views/DashboardView/TemporaryDashboard'),
  },
  {
    path: '/:orgCode/(.*?/|)entities',
    file: () => import('src/views/organization/EntitiyListView'),
  },
  {
    path: '/:orgCode/(.*?/|)entities/create',
    file: () => import('src/views/organization/EntityCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)entities/:id',
    file: () => import('src/views/organization/EntityEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-parameters',
    file: () => import('src/views/catalog/parameters/ParametersView'),
  },
  {
    path: pathCategory,
    file: () => import('src/views/categories/CategoryItemsView'),
  },
  {
    path: '/:orgCode/(.*?/|)categories',
    file: () => import('src/views/categories/CategoriesView'),
  },
  {
    path: '/:orgCode/(.*?/|)categories/create',
    file: () => import('src/views/categories/CategoryCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)categories/:id',
    file: () => import('src/views/categories/CategoryEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers-parameters',
    file: () =>
      import('src/views/catalog/manufacturers/parameters/ParametersView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers',
    file: () => import('src/views/catalog/manufacturers/ManufacturersView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers/create',
    file: () =>
      import('src/views/catalog/manufacturers/ManufacturersCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers/:id',
    file: () => import('src/views/catalog/manufacturers/ManufacturersEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)news',
    file: () => import('src/views/news/NewsView'),
  },
  {
    path: '/:orgCode/(.*?/|)news/create',
    file: () => import('src/views/news/NewsCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)news/:id',
    file: () => import('src/views/news/NewsEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products',
    file: () => import('src/views/catalog/products/ProductsBasicView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products/create',
    file: () => import('src/views/catalog/products/ProductCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products/:id',
    file: () => import('src/views/catalog/products/ProductEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products-tree',
    file: () => import('src/views/catalog/products/ProductsTreeView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products-tree/create',
    file: () => import('src/views/catalog/products/ProductCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products-tree/:id',
    file: () => import('src/views/catalog/products/ProductEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)users',
    file: () => import('src/views/organization/users/UsersListView'),
  },
  {
    path: '/:orgCode/(.*?/|)users/create',
    file: () => import('src/views/organization/users/UserCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)users/:id',
    file: () => import('src/views/organization/users/UserEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)acls',
    file: () => import('src/views/security/acls/AclsListView'),
  },
  {
    path: '/:orgCode/(.*?/|)profileParameters',
    file: () =>
      import('src/views/security/profileParameters/ProfileParametersListView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles',
    file: () => import('src/views/security/profiles/ProfilesListView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles/create',
    file: () => import('src/views/security/profiles/ProfileCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles/duplicate/:id',
    file: () => import('src/views/security/profiles/ProfileDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles/:id',
    file: () => import('src/views/security/profiles/ProfileEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process',
    file: () => import('src/views/process/ProcessView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-rules',
    file: () => import('src/views/process/rules/ProcessRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-rules/create',
    file: () => import('src/views/process/rules/ProcessRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-rules/:id',
    file: () => import('src/views/process/rules/ProcessRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-workflow/:processCode',
    file: () => import('src/views/process/workflow/ProcessWorkflowView'),
  },

  {
    path: '/:orgCode/(.*?/|)logistic-delivery-rules',
    file: () => import('src/views/logistic/delivery/rules/DeliveryRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-delivery-rules/create',
    file: () =>
      import('src/views/logistic/delivery/rules/DeliveryRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-delivery-rules/:id',
    file: () =>
      import('src/views/logistic/delivery/rules/DeliveryRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)templates',
    file: () => import('src/views/template/TemplateListView'),
  },
  {
    path: '/:orgCode/(.*?/|)templates/create',
    file: () => import('src/views/template/TemplateCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)templates/:id',
    file: () => import('src/views/template/TemplateEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-workflow',
    file: () => import('src/views/logistic/workflow/LogisticWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)order-workflow',
    file: () => import('src/views/order/workflow/OrderWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)quotation-workflow',
    file: () => import('src/views/quotation/workflow/QuotationWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)return-workflow',
    file: () =>
      import('src/views/returnRequest/workflow/ReturnRequestWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)treatment-workflow',
    file: () => import('src/views/treatment/workflow/TreatmentWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)operation-rules',
    file: () =>
      import('src/views/treatment/operationRules/TreatmentOperationRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)operation-rules/create',
    file: () =>
      import(
        'src/views/treatment/operationRules/TreatmentOperationRuleCreateView'
      ),
  },
  {
    path: '/:orgCode/(.*?/|)operation-rules/:id',
    file: () =>
      import(
        'src/views/treatment/operationRules/TreatmentOperationRuleEditView'
      ),
  },
  {
    path: pathList,
    file: () => import('src/views/list/ListView'),
  },
  {
    path: pathMapGroup,
    file: () => import('src/views/mapGroup/MapGroupView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-users',
    file: () => import('src/views/api/users/ApiUsersView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-users/create',
    file: () => import('src/views/api/users/ApiUsersCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-users/:id',
    file: () => import('src/views/api/users/ApiUsersEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)task-types',
    file: () => import('src/views/task/type/TaskTypesListView'),
  },
  {
    path: '/:orgCode/(.*?/|)task-types/create',
    file: () => import('src/views/task/type/TaskTypeCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)task-types/:id',
    file: () => import('src/views/task/type/TaskTypeEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)tasks',
    file: () => import('src/views/task/TasksListView'),
  },
  {
    path: '/:orgCode/(.*?/|)tasks/create',
    file: () => import('src/views/task/TaskCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)tasks/:id',
    file: () => import('src/views/task/TaskEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies',
    file: () => import('src/views/insurance/policies/InsurancePoliciesView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies/create',
    file: () =>
      import('src/views/insurance/policies/InsurancePolicyCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies/duplicate/:id',
    file: () =>
      import('src/views/insurance/policies/InsurancePolicyDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies/:id',
    file: () => import('src/views/insurance/policies/InsurancePolicyEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-equipements',
    file: () =>
      import('src/views/insurance/equipments/InsuranceEquipmentsView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-equipements/create',
    file: () =>
      import('src/views/insurance/equipments/InsuranceEquipmentCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-equipements/:id',
    file: () =>
      import('src/views/insurance/equipments/InsuranceEquipmentEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)trade-in-grid-prices',
    file: () => import('src/views/tradeIn/gridPrices/GridPricesListView'),
  },
  {
    path: '/:orgCode/404',
    file: () => import('src/views/pages/Error404View'),
  },
  {
    path: '/:orgCode/(.*?/|)quote-option-rules',
    file: () => import('src/views/quoteOption/rules/QuoteOptionRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)quote-option-rules/create',
    file: () =>
      import('src/views/quoteOption/rules/QuoteOptionRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)quote-option-rules/:id',
    file: () => import('src/views/quoteOption/rules/QuoteOptionRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-rules',
    file: () => import('src/views/warranty/rules/WarrantyRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-rules/create',
    file: () => import('src/views/warranty/rules/WarrantyRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-rules/:id',
    file: () => import('src/views/warranty/rules/WarrantyRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)dictionary-data',
    file: () => import('src/views/DictionaryData/DictionaryDataListView'),
  },
  {
    path: '/:orgCode/(.*?/|)dictionary-data/create',
    file: () => import('src/views/DictionaryData/DictionaryDataCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)dictionary-data/:id',
    file: () => import('src/views/DictionaryData/DictionaryDataEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)configuration-ui',
    file: () => import('src/views/ConfigurationUI/ConfigurationUIItemsView'),
  },
];

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/404" />
        <Route
          exact
          path="/404"
          component={lazy(() => import('src/views/pages/notfound'))}
        />

        <GuestRoute
          exact
          path="/:orgCode/login"
          //component={lazy(() => import('src/views/login'))}
          render={(props) => <Login {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/oauth"
          //component={lazy(() => import('src/views/login/OAuth'))}
          render={(props) => <OAuth {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/oauthFail"
          //component={lazy(() => import('src/views/login/OAuthFail'))}
          render={(props) => <OAuthFail {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/oauthSuccess"
          //component={lazy(() => import('src/views/login/OAuthSuccess'))}
          render={(props) => <OAuthSuccess {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/disconnected"
          //component={lazy(() => import('src/views/login/Logout'))}
          render={(props) => <Logout {...props} />}
        />

        <AuthRoute
          path="/:orgCode/"
          render={(props) => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect exact from="/:orgCode/" to="/:orgCode/dashboard" />

                  {routes.map((item, index) => (
                    <Route
                      exact
                      lazy
                      path={item.path}
                      component={lazy(item.file)}
                      key={index}
                    />
                  ))}

                  {/*<Redirect
                    to={'/' + props.computedMatch.params.orgCode + '/404'}
                  />*/}

                  <Route
                    lazy
                    //component={lazy(() => import('src/views/pages/notfound'))}
                    render={() => <NotFound {...props} />}
                  />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />
      </Switch>
    </Suspense>
  );
}

export default Routes;
