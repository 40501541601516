import React from 'react';
import { Tooltip, TooltipProps, IconButton } from '@mui/material';

interface TooltipPropsCustomInterface extends TooltipProps {
  iconName?: string;
}

export const TooltipComponent: React.FC<TooltipPropsCustomInterface> = ({
  children,
  title,
  ...rest
}) => {
  return title ? (
    <Tooltip title={title} {...rest}>
      <IconButton size="large">{children}</IconButton>
    </Tooltip>
  ) : null;
};
