import * as React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import { SnackbarProvider } from 'notistack';
import { ConfirmationServiceProvider } from 'src/components/ConfirmDialog';
import { useSettings } from 'src/context/SettingsContext';
import { createTheme } from 'src/theme';
import { ApiProvider } from './ApiContext';
import { LoadingScreenProvider } from './LoadingScreenContext';
import config from 'src/config';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const localeMap: { [key: string]: any } = {
  en_UK: enLocale,
  fr_FR: frLocale,
};

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: number | Date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeUtilsMap: { [key: string]: any } = {
  en_UK: DateFnsUtils,
  fr_FR: FrLocalizedUtils,
  fr: FrLocalizedUtils,
};

const AppProviders = ({ children }: { children?: React.ReactNode }) => {
  const { settings } = useSettings();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(settings)}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          utils={
            localeUtilsMap[settings.locale] ||
            localeUtilsMap[config.app.defaultLang]
          }
          locale={
            localeMap[settings.locale] || localeMap[config.app.defaultLang]
          }>
          <LoadingScreenProvider>
            <SnackbarProvider maxSnack={6}>
              <ApiProvider>
                <ConfirmationServiceProvider>
                  {children}
                </ConfirmationServiceProvider>
              </ApiProvider>
            </SnackbarProvider>
          </LoadingScreenProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export * from './ApiContext';
export * from './PreviewContext';
export * from './SettingsContext';
export { AppProviders };
