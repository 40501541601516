export function getToken(): string {
  const token = sessionStorage.getItem('token') as string;
  return token;
}

export function setToken(token: string) {
  sessionStorage.setItem('token', token);
}

export function removeToken() {
  sessionStorage.removeItem('token');
}
