import React from 'react';
import { DataAction } from 'src/interfaces/DataAction';

interface PreviewContextInterface {
  data?: any;
  previewContent?: any;
  preview: (data?: any, content?: any) => void;
  dataAction: DataAction | undefined;
  setDataAction: (dataAction: DataAction | undefined) => void;
}

const defaultPreviewContext: PreviewContextInterface = {
  preview: () => null,
  dataAction: undefined,
  setDataAction: () => null,
};

export const PreviewContext = React.createContext<PreviewContextInterface>(
  defaultPreviewContext
);
