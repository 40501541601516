import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import TopBar from 'src/layout/DashboardLayout/TopBar';
import NavBar from './NavBar';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { ApiContext, LoginInformationsInterface } from 'src/context/ApiContext';
import ImplementHelper from 'src/helpers/ImplementHelper';
import { LoadingScreenContext } from 'src/context/LoadingScreenContext';
import { useSettings } from 'src/context';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import LogoutHelper from 'src/helpers/LogoutHelper';
import { setToken } from 'src/utils/token';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64,
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.dark,
    },
  })
);

const DashboardLayout: React.FC = ({ children, ...rest }) => {
  const getOrgCode = () => {
    // @ts-ignore
    return rest.computedMatch.params.orgCode;
  };
  const { enqueueSnackbar } = useSnackbar();

  const apiContext = useContext(ApiContext);
  const { saveSettings, removeSettings, settings } = useSettings();
  const { pathname } = useLocation();

  const logout = () => {
    LogoutHelper.logout(getOrgCode(), settings);
  };

  useEffect(() => {
    if (sessionStorage.getItem('loginInfos')) {
      const loginInformations: LoginInformationsInterface = JSON.parse(
        sessionStorage.getItem('loginInfos')!
      ) as LoginInformationsInterface;
      if (loginInformations.orgCode === getOrgCode()) {
        apiContext.setLoginInfos(loginInformations);
      } else {
        logout();
      }
    } else {
      logout();
    }

    removeSettings(['currentPath']);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  const [isNavOpen, setNavOpen] = useState(false);
  const loadingScreenContext = useContext(LoadingScreenContext);

  useEffect(() => {
    const myRequestInterceptor = axios.interceptors.request.use(function (
      request
    ) {
      if (
        request.method &&
        ['POST', 'PUT', 'PATCH', 'DELETE'].includes(
          request.method.toUpperCase()
        )
      ) {
        loadingScreenContext.setLoading(true);
      }
      // Do something with request data
      return request;
    });

    const myResponseInterceptor = axios.interceptors.response.use(
      function (response) {
        loadingScreenContext.setLoading(false);

        if (response.headers['token']) {
          setToken(response.headers['token']);
        }
        return response;
      },
      function (error) {
        loadingScreenContext.setLoading(false);
        if (
          error?.response?.data &&
          ImplementHelper.isErrorType(error.response.data)
        ) {
          enqueueSnackbar(error.response.data.message!, { variant: 'error' });
        } else {
          enqueueSnackbar(String(error), { variant: 'error' });
        }
        if (error?.response?.status) {
          switch (error.response.status) {
            case 401:
              axios.interceptors.response.eject(myResponseInterceptor);
              logout();
              break;
            default:
              break;
          }
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(myRequestInterceptor);
      axios.interceptors.response.eject(myResponseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <TopBar onNavOpen={() => setNavOpen(!isNavOpen)} {...rest} />
      <NavBar onClose={() => setNavOpen(false)} open={isNavOpen} {...rest} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Breadcrumb orgCode={getOrgCode()} />

            <Grid container spacing={0}>
              <Grid item xs>
                {children}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
