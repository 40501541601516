import React from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { ValidationStateSchema } from 'src/interfaces';
import ValidationStateSchemaHelper from 'src/helpers/ValidationStateSchemaHelper';

interface TextFieldComponentProps extends StandardTextFieldProps {
  validationSchema?: ValidationStateSchema;
}

export const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  validationSchema,
  required,
  name,
  ...rest
}) => {
  const onFocus = (event: any) => {
    event.target.setAttribute('autocomplete', 'off');
  };

  return (
    <TextField
      variant="outlined"
      name={name}
      required={
        validationSchema && name
          ? ValidationStateSchemaHelper.isRequired(validationSchema, name)
          : required
      }
      {...rest}
      autoComplete="new-password"
      onFocus={onFocus}
    />
  );
};
