import { ErrorType } from 'src/api/api-gc/model';

const ImplementHelper = {
  isErrorType(value: any): value is ErrorType {
    //magic happens here
    return (
      (value as ErrorType).status !== undefined &&
      (value as ErrorType).code !== undefined
    );
  },
};

export default ImplementHelper;
